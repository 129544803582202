import React, { useRef, useEffect } from 'react'
import 'assets/scss/components/header.scss';
import videoPortrateMp4 from 'assets/movies/header-movie-portrate.mp4';
import videoPortrateWebm from 'assets/movies/header-movie-portrate.webm';
import videoLandscapeMp4 from 'assets/movies/header-movie-landscape.mp4';
import videoLandScapeWebm from 'assets/movies/header-movie-landscape.webm';
import videoCover from 'assets/images/header/cover.png';

const Header = () => {
    const portrateVideoRef = useRef<HTMLVideoElement>(null);
    const landscapeVideoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        // const isPortrate = window.matchMedia("(orientation: portrate)").matches;
        const isPortrate = window.innerHeight > window.innerWidth;
        console.info(isPortrate);
        if (isPortrate) {
            portrateVideoRef.current!.style.display = 'block';
            portrateVideoRef.current?.play();
        } else {
            landscapeVideoRef.current!.style.display = 'block';
            landscapeVideoRef.current?.play();
        }
    }, []);

    return (
        <header id="header" className="header">
            <div className="header--video">
                <video className='video-portrate' controls muted loop playsInline preload='true' ref={portrateVideoRef} poster={videoCover}>
                    <source src={videoPortrateMp4} type='video/mp4' />
                    <source src={videoPortrateWebm} type='video/webm' />
                </video>
                <video className='video-landscape' controls muted loop playsInline preload='true' ref={landscapeVideoRef} poster={videoCover}>
                    <source src={videoLandscapeMp4} type='video/mp4' />
                    <source src={videoLandScapeWebm} type='video/webm' />
                </video>
            </div>
        </header>
    )
};

export default Header;
