import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import AdminModal from "components/AdminModal";
import AdminUserLayout from "layouts/AdminUserLayout";
import { api_url } from "Config";

interface DateAddResponse {
    status: "success" | "failed";
    code: number;
    message: string;
}

interface DateItem {
    id: number;
    date: string;
}
interface DateListResponseFailed {
    status: "failed";
    code: number;
    message: string;
}
interface DateListResponseSuccess {
    status: "success";
    code: number;
    data: DateItem[];
}

interface DateDeleteResponseFailed {
    status: "failed";
    code: number;
    message: string;
}
interface DateDeleteResponseSuccess {
    status: "success";
    code: number;
}

const AdminReserveStop: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [dateInput, setDateInput] = useState<string>("");
    const [dateInputError, setDateInputError] = useState<string>("");
    const [dateAddSuccess, setDateAddSuccess] = useState<string>("");
    const [dateList, setDateList] = useState<DateItem[]>([]);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>("");

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await fetch(`${api_url}/admin-reserve-stop.php`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });

            const data = await res.json() as DateListResponseSuccess | DateListResponseFailed;
            if (data.status === "success") {
                setDateList(data.data);
            }
            console.info(data);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleSubmitStopAdd = async (e: React.FormEvent) => {
        e.preventDefault();
        
        try {
            setIsLoading(true);
            setDateInputError("");
            setDateAddSuccess("");

            if (!dateInput || dateInput === "") {
                setDateInputError("日付を入力してください");
                setIsLoading(false);
                return false;
            }

            const date = new Date(dateInput);
            if (date < new Date()) {
                setDateInputError("過去は指定できません");
                setIsLoading(false);
                return false;
            }

            const res = await fetch(`${api_url}/admin-reserve-stop.php`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({date: dateInput}),
            });

            const data = await res.json() as DateAddResponse;

            if (data.status === "success") {
                setDateAddSuccess(data.message);
                setDateInput("");
                await fetchData();
            } else {
                setDateInputError(data.message);
                setIsLoading(false);
            }

        } catch (error) {
            if (typeof (error) === "string") {
                setDateInputError(error);
            } else if (error instanceof Error) {
                setDateInputError(error.message);
            } else {
                setDateInputError("予期せぬエラーが発生しました");
            }
            setIsLoading(false);
        } finally {
        }
    }

    const handleInputDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setDateInput(value);
    }

    const handleDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleting(true);
    }

    const handleDeleteCancel = () => {
        setDeleteId(null);
        setIsDeleting(false);
    }

    const handleDeleteDo = async () => {
        try {
            const res = await fetch(`${api_url}/admin-reserve-stop.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({id: deleteId})
            });

            const data = await res.json() as DateDeleteResponseSuccess | DateDeleteResponseFailed;

            if (data.status === "failed") {
                setDeleteErrorMessage(data.message);
            } else {
                setDeleteErrorMessage("");
                setDeleteId(null);
                setIsDeleting(false);
                setIsLoading(true);
                fetchData();
            }
        } catch (error) {
            if (typeof (error) === "string") {
                setDeleteErrorMessage(error);
            } else if (error instanceof Error) {
                setDeleteErrorMessage(error.message);
            } else {
                setDeleteErrorMessage("予期せぬエラーが発生しました");
            }
        }
    }

    return (
        <AdminUserLayout>
            <>
                <h1>予約受付停止設定</h1>
                <div className="card">
                    <h2>停止設定追加</h2>
                    <p>停止日にすでに入っている予約は予約状態で残り、削除されません。</p>
                    <form onSubmit={handleSubmitStopAdd}>
                        {dateInputError && (
                            <div className="error">{dateInputError}</div>
                        )}
                        {dateAddSuccess && (
                            <div className="success">{dateAddSuccess}</div>
                        )}
                        <div className="card-form-row">
                            <input
                                type="date"
                                name="date"
                                value={dateInput}
                                onChange={handleInputDateChange}
                            />
                            <button type="submit" className="primary">追加</button>
                        </div>
                    </form>
                </div>
                <div className="card">
                    <h2>設定一覧</h2>
                    <p>過去分は表示されません</p>
                    {dateList && (
                        <table className="list">
                            <thead>
                                <tr>
                                    <th>日付</th>
                                    <th style={{'width': '40px'}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dateList.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.date}</td>
                                        <td><button className="danger" onClick={() => handleDelete(data.id)}>削除</button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>

                {isLoading && (
                    <AdminModal>
                        <div className="loading">
                            <p>読み込み中</p>
                            <AiOutlineLoading3Quarters />
                        </div>
                    </AdminModal>
                )}
                {isDeleting && (
                    <AdminModal>
                        <>
                            <p>削除してもよろしいですか？<br />
                            この操作は取り消せません。</p>
                            {deleteErrorMessage && (
                                <div className="error">{deleteErrorMessage}</div>
                            )}
                            <div className="buttons">
                                <button className="cancel" onClick={handleDeleteCancel}>キャンセル</button>
                                <button className="danger" onClick={handleDeleteDo}>削除</button>
                            </div>
                        </>
                    </AdminModal>
                )}
            </>
        </AdminUserLayout>
    )
}

export default AdminReserveStop
