import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AdminUserLayout from "layouts/AdminUserLayout";
import { api_url } from "Config";

type PlanDataResponse = {
    status: "success" | "failed";
    code: number;
    data?: PlanData | undefined;
    message?: string | undefined;
}

type PlanData = {
    id: number;
    name: string;
    description: string | null;
    is_active: "1" | "0";
}

const AdminPlan: React.FC = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [resultMessage, setResultMessage] = useState<string>("");
    const [resultStatus, setResultStatus] = useState<"" | "success" | "error">("");
    const [planData, setPlanData] = useState<PlanData>({
        id: Number(id),
        name: "",
        description: "",
        is_active: "1"
    });

    useEffect(() => {
        const fetchPlanData = async () => {
            setErrorMessage("");
            try {
                const res = await fetch(`${api_url}/admin-plan.php?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include'
                });

                const data = await res.json() as PlanDataResponse;

                if (data.code !== 200) {
                    throw new Error(data.message);
                }

                setPlanData(() => ({
                    id: data.data!.id,
                    name: data.data!.name,
                    description: data.data!.description,
                    is_active: data.data!.is_active,
                }) as PlanData);

                setResultStatus('success');

            } catch (error) {
                setResultStatus('error');
                if (typeof(error) === "string") {
                    setErrorMessage(error);
                } else if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage('Unknown error');
                }
            } finally {
                setIsLoading(false);
            }
        }

        fetchPlanData();
    }, [setIsLoading, id, setResultMessage]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPlanData((prevPlanData) => ({
            ...prevPlanData,
            [name]: value,
        }) as PlanData);
    }

    const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setPlanData((prevPlanData) => ({
            ...prevPlanData,
            [name]: value,
        }) as PlanData);
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const res = await fetch(`${api_url}/admin-plan.php`, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(planData),
            });

            const data = await res.json() as PlanDataResponse;

            if (data.code !== 200) {
                throw new Error(data.message);
            }

            setResultMessage('更新が完了しました。');
            setResultStatus("success");
        } catch (error) {
            setResultStatus('error');
            if (typeof(error) === "string") {
                setResultMessage(error);
            } else if (error instanceof Error) {
                setResultMessage(error.message);
            } else {
                setResultMessage('Unknown Error');
            }
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return (
            <AdminUserLayout>
                Loading...
            </AdminUserLayout>
        )
    }

    return (
        <AdminUserLayout>
            <h3>Plan Update</h3>
            {errorMessage ? (
                <p className="ft-error">{errorMessage}</p>
            ) : (
                <form name="form" onSubmit={handleSubmit}>
                    {resultMessage && (
                        <p className={resultStatus !== "" ? `ft-${resultStatus}` : ""}>{resultMessage}</p>
                    )}
                    <div className="data-view">
                        <dl className="data-item">
                            <dt>ID</dt>
                            <dd>{planData.id.toString()}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>プラン名</dt>
                            <dd>
                                <input
                                    type="text"
                                    name="name"
                                    value={planData.name}
                                    onChange={handleInputChange}
                                />
                                <div className="hint">
                                    <ul className="hint-list">
                                        <li>%m: 予約画面で自動的に月が入力されます</li>
                                    </ul>
                                </div>
                            </dd>
                        </dl>
                        <dl className="data-item">
                            <dt>説明</dt>
                            <dd>
                                <textarea
                                    name="description"
                                    maxLength={2000}
                                    value={planData.description ?? ""}
                                    onChange={handleTextareaChange}
                                ></textarea>
                            </dd>
                        </dl>
                    </div>
                    <div className="align-center">
                        <button className="primary">保存</button>
                    </div>
                </form>
            )}
        </AdminUserLayout>
    )
}

export default AdminPlan
