import React from "react";

interface AdminInputErrorProps {
    children?: React.ReactNode;
}

const AdminInputError: React.FC<AdminInputErrorProps> = (props: AdminInputErrorProps) => {
    if (!props.children) {
        return <></>
    }

    return (
        <p className="inputError">{props.children}</p>
    )
}

export default AdminInputError
