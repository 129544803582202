import AdminSidebar from "components/AdminSidebar";
import React from "react";

interface AdminUserLayoutProps {
    children?: React.ReactNode;
}

const AdminUserLayout: React.FC<AdminUserLayoutProps> = (props: AdminUserLayoutProps) => {
    return (
        <div className="admin admin-2col-layout">
            <AdminSidebar />
            <div className="main-content">
                {props.children}
            </div>
        </div>
    )
}

export default AdminUserLayout;
