import { api_url } from "Config";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

type TokenValidateResponse = {
    status: "success" | "failed";
    code: number;
    message: string;
}

type PasswordResetResponse = {
    status: "success" | "failed";
    code: number;
    message: string;
}

const AdminPasswordReset: React.FC = () => {
    const { code } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isTokenValidated, setIsTokenValidated] = useState<boolean>(false);
    const [resultMessage, setResultMessage] = useState<string>("");

    const [password, setPassword] = useState<string>("");
    const [confirmation, setConfirmation] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchTokenTest = async () => {
            try {
                const res = await fetch(`${api_url}/admin-password-reset.php?code=${code}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const data = await res.json() as TokenValidateResponse;
                if (data.code !== 200) {
                    throw new Error(data.message);
                }

                setIsTokenValidated(true);
            } catch (error) {
                setIsTokenValidated(false);
                if (typeof(error) === "string") {
                    setResultMessage(error);
                } else if (error instanceof Error) {
                    setResultMessage(error.message);
                } else {
                    setResultMessage("Unknown error");
                }
            } finally {
                setIsLoading(false);
            }
        }

        fetchTokenTest();
    }, [setIsLoading, code]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            const res = await fetch(`${api_url}/admin-password-reset.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token: code,
                    password: password,
                    confirmation: confirmation,
                }),
            });

            const data = await res.json() as PasswordResetResponse;

            if (data.code !== 200) {
                throw new Error(data.message);
            }

            setIsSuccess(true);

        } catch (error) {
            setIsSuccess(false);
            if (typeof(error) === "string") {
                setResultMessage(error);
            } else if (error instanceof Error) {
                setResultMessage(error.message);
            } else {
                setResultMessage('Unknown error');
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleOpenLogin = () => {
        navigate("/admin/login");
    }

    if (isLoading) {
        return <div>Loading...</div>
    }
    
    return (
        <div className="admin center-container">
            <main className="login">
                <header>
                    <h1>パスワードリセット</h1>
                </header>
                {isSuccess && (
                    <div>
                        <p>パスワードを変更しました</p>
                        <button onClick={handleOpenLogin} className="primary">ログイン画面を開く</button>
                    </div>
                )}
                {!isSuccess && isTokenValidated && (
                    <>
                        <div className="login-form-content">
                            <p>
                                <label>新しいパスワード</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </p>
                            <p>
                                <label>確認</label>
                                <input
                                    type="password"
                                    name="confirmation"
                                    value={confirmation}
                                    onChange={(e) => setConfirmation(e.target.value)}
                                />
                            </p>
                        </div>
                        <div>
                            <button onClick={handleSubmit} className="login-button">変更する</button>
                        </div>
                    </>
                )}
                {resultMessage && (
                    <div>{resultMessage}</div>
                )}
            </main>
        </div>
    )
}

export default AdminPasswordReset
