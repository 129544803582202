import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "Config";
import AdminUserLayout from "layouts/AdminUserLayout";

type UserDataResponse = {
    status: "success" | "failed";
    code: number;
    data?: UserData[] | undefined;
    message?: string | undefined;
}

type UserData = {
    id: number;
    login_id: string;
    is_active: "1" | "0";
}

const AdminUsers: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [userData, setUserData] = useState<UserData[]>([]);
    const navigate = useNavigate();

    const handleGetUsers = async () => {
        try {
            const res = await fetch(`${api_url}/admin-users.php`, {
                method: 'GET',
                credentials: 'include',
            });
            
            if (!res.ok) {
                throw Error("Failed to load user list.");
            }

            const data: UserDataResponse = await res.json();
            if (data.code !== 200) {
                throw Error(data.message!);
            }
            setUserData(data.data!);
        } catch (error) {
            console.error(error);
            setErrorMessage("Failed to load user list");
        } finally {
            setIsLoading(false);
        }
    }

    const handleEdit = (id: number) => {
        navigate(`/admin/user/${id}`);
    }

    useEffect(() => {
        handleGetUsers();
    }, [setIsLoading]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (errorMessage) {
        <AdminUserLayout>
            <p>Failed to load</p>
        </AdminUserLayout>
    } 

    return (
        <AdminUserLayout>
            <h2>管理者一覧</h2>
            <div>
                <table className="list">
                    <thead>
                        <tr>
                            <th style={{'width': '30px'}}>ID</th>
                            <th>ログインID</th>
                            <th style={{'width': '40px'}}>状態</th>
                            <th style={{'width': '40px'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData.map((data, index) => (
                            <tr key={`list_${index}`}>
                                <td>{data.id.toString()}</td>
                                <td>{data.login_id}</td>
                                <td>{data.is_active === "1" ? "有効" : "無効" }</td>
                                <td><button className="primary" onClick={() => handleEdit(data.id)}>編集</button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </AdminUserLayout>
    )
}

export default AdminUsers
