import React from "react";

interface AdminModalProps {
    children?: React.ReactNode;
}

const AdminModal: React.FC<AdminModalProps> = (props: AdminModalProps) => {
    if (!props.children) {
        return <></>
    }

    return (
        <div className="backdrop">
            <div className="backdrop-container">
                <div className="backdrop-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default AdminModal;
