import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AdminLayout from "layouts/AdminLayout";
import AdminProtected from "components/AdminProtected";
import { AdminAuthProvider } from "components/AdminAuthContext";

import HomePage from "pages/Home";
import PageNotFound from "pages/PageNotFound";
import AdminLogin from "pages/AdminLogin";
import AdminDashboard from "pages/AdminDashboard";
import AdminUsers from "pages/AdminUsers";
import AdminUserEdit from "pages/AdminUserEdit";
import AdminUserAdd from "pages/AdminUserAdd";
import AdminPlan from "pages/AdminPlan";
import AdminPasswordChange from "pages/AdminPasswordChange";
import AdminPasswordReset from "pages/AdminPasswordReset";
import Reserve from "pages/Reserve";
import AdminReserved from "pages/AdminReserved";
import AdminReserveInfo from "pages/AdminReserveInfo";
import AdminReserveEdit from "pages/AdminReserveEdit";
import AdminReserve from "pages/AdminReserve";
import AdminReserveStop from "pages/AdminReserveStop";
import AdminReserveStopPerDateTime from "pages/AdminReserveStopPerDateTime";

const AppRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={ <HomePage /> } />
                <Route path="/reserve" element={ <Reserve /> } />

                <Route path="/admin" element={
                    <AdminAuthProvider>
                        <AdminLayout />
                    </AdminAuthProvider>
                }>
                    <Route path="/admin/login" element={ <AdminLogin /> } />
                    <Route path="/admin/password-change" element={ <AdminPasswordChange /> } />
                    <Route path="/admin/password-reset/:code" element={ <AdminPasswordReset /> } />
                    <Route element={ <AdminProtected /> }>
                        <Route index element={ <AdminDashboard /> } />
                        <Route path="/admin/users" element={ <AdminUsers /> } />
                        <Route path="/admin/user/:id" element={ <AdminUserEdit /> } />
                        <Route path="/admin/user/add" element={ <AdminUserAdd /> } />
                        <Route path="/admin/plan/:id" element={ <AdminPlan /> } />
                        <Route path="/admin/reserved" element={ <AdminReserved /> } />
                        <Route path="/admin/reserve/:id" element={ <AdminReserveInfo /> } />
                        <Route path="/admin/reserve/edit/:id" element={ <AdminReserveEdit /> } />
                        <Route path="/admin/reserve/add" element={ <AdminReserve /> } />
                        <Route path="/admin/reserve/stop" element={ <AdminReserveStop /> } />
                        <Route path="/admin/reserve/stop-setting" element={ <AdminReserveStopPerDateTime /> } />
                    </Route>
                </Route>

                <Route path="*" element={ <PageNotFound /> } />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes
