import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom"
import { api_url } from "Config";
import AdminUserLayout from "layouts/AdminUserLayout";
import BackDropPopup from "components/BackDropPopup";


type UserDataResponse = {
    status: string;
    code: number;
    data?: UserData | undefined;
    message?: string | undefined;
}

type UserData = {
    id: number;
    login_id: string;
    is_active: "1" | "0";
}

const AdminUserEdit: React.FC = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [resultStatus, setResultStatus] = useState<"" | "success" | "error">("");
    const [resultMessage, setResultMessage] = useState<string>("");
    const [userData, setUserData] = useState<UserData | null>(null);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const res = await fetch(`${api_url}/admin-user.php?id=${id}`, {
                    method: 'GET',
                    credentials: 'include'
                });
                if (!res.ok) {
                    throw new Error("Error to load user detail.");
                }

                const data = await res.json() as UserDataResponse;
                if (data.code !== 200) {
                    throw new Error(data.message!);
                }

                setUserData(data.data!);
            } catch (error) {
                if (typeof(error) === "string") {
                    setErrorMessage(error);
                } else {
                    setErrorMessage("Failed");
                }
            } finally {
                setIsLoading(false);
            }
        }

        fetchUserData();
    }, [id, setIsLoading, setErrorMessage, setUserData]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }) as UserData);
    };

    const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value === "1" ? "1" : "0",
        }) as UserData);
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const res = await fetch(`${api_url}/admin-user.php`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                body: JSON.stringify(userData),
            });

            const data = await res.json() as UserDataResponse;

            if (data.code !== 200) {
                throw new Error(data.message);
            }

            setResultMessage("更新しました");
            setResultStatus("success");
        } catch (error) {
            setResultStatus("error");
            if (typeof error === "string") {
                setResultMessage(error);
            } else if (error instanceof Error) {
                setResultMessage(error.message);
            } else {
                setResultMessage("failed");
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleDeletePopup = () => setShowPopup(true);
    const handlePopupCancel = () => setShowPopup(false);
    const handlePopupDelete = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const res = await fetch(`${api_url}/admin-user.php`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: userData?.id
                })
            });

            const data = await res.json() as UserDataResponse;
            
            if (data.code !== 200) {
                throw new Error(data.message);
            }

            // complete
            navigate('/admin/users');
        } catch (error) {
            if (typeof(error) === "string") {
                setErrorMessage(error);
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("Unknown error");
            }
        } finally {
            setShowPopup(false);
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <div>loading...</div>
    }

    if (errorMessage) {
        return (
            <AdminUserLayout>
                <div>
                    <p className={resultStatus}>{errorMessage}</p>
                </div>
            </AdminUserLayout>
        );
    }
    
    return (
        <AdminUserLayout>
            <div className="title-with-button">
                <h2>管理者情報編集</h2>
                <button className="danger" onClick={handleDeletePopup}>削除</button>
            </div>
            {resultMessage && <p className={resultStatus !== "" ? `ft-${resultStatus}` : ""}>{resultMessage}</p>}
            <div className="admin-form">
                <form className="admin-form" onSubmit={handleSubmit}>
                    <div className="data-view">
                        <dl className="data-item">
                            <dt>ID</dt>
                            <dd>{id?.toString()}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>ログインID</dt>
                            <dd>
                                <input
                                    type="email"
                                    name="login_id"
                                    value={userData?.login_id}
                                    onChange={handleInputChange}
                                    placeholder="ログインID"
                                />
                            </dd>
                        </dl>
                        <dl className="data-item">
                            <dt>状態</dt>
                            <dd>
                            <ul className="radio-list">
                                    <li>
                                        <input
                                            type="radio"
                                            id="is_active_true"
                                            name="is_active"
                                            value="1"
                                            checked={userData?.is_active === "1"}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor="is_active_true">有効</label>
                                    </li>
                                    <li>
                                        <input
                                            type="radio"
                                            id="is_active_false"
                                            name="is_active"
                                            value="0"
                                            checked={userData?.is_active !== "1"}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor="is_active_false">無効</label>
                                    </li>
                                </ul>
                            </dd>
                        </dl>
                    </div>
                    <div className="align-center">
                        <button className="primary">保存</button>
                    </div>
                </form>
            </div>
            {showPopup && (
                <BackDropPopup>
                    <h3>次のユーザーを削除します</h3>
                    <p style={{marginBottom: "2rem"}}>{userData?.login_id}</p>
                    <div className="flex-buttons">
                        <button className="cancel" onClick={handlePopupCancel}>キャンセル</button>
                        <button className="danger" onClick={handlePopupDelete}>削除</button>
                    </div>
                </BackDropPopup>
            )}
        </AdminUserLayout>
    )
}

export default AdminUserEdit
