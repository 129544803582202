import React from "react";

interface BackDropPopupProps {
    children?: React.ReactNode;
}

const BackDropPopup: React.FC<BackDropPopupProps> = (props: BackDropPopupProps) => {
    return (
        <div className="backdrop">
            <div className="backdrop-container">
                {props.children}
            </div>
        </div>
    )
}

export default BackDropPopup
