import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { useAdminAuth } from "components/AdminAuthContext";
import "assets/admin/scss/style.scss";

const AdminLayout: React.FC = () => {
    const { isAuthenticated, logout } = useAdminAuth();
    const navigate = useNavigate();
    const ignoreHeaderPages = [
        '/admin/login',
        '/admin/password-change',
        '/admin/code-validate',
        '/admin/password-reset',
    ];

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/admin/login');
        } catch (error) {
            console.error("Logout failed: ", error);
        }
    };

    const currentPath = document.location.pathname;
    
    return (
        <>
            <Helmet>
                <title>Admin</title>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            {isAuthenticated ? (
                <header className="admin header-global">
                    <Link to="/admin">予約管理</Link>
                    <button onClick={handleLogout}>Logout</button>
                </header>
            ) : (ignoreHeaderPages.indexOf(currentPath) === -1 && (
                <header className="admin header-global">
                    <Link to="/admin">予約管理</Link>
                    <Link to="/admin/login">Login</Link>
                </header>
            ))}
            <Outlet />
            <style>
                {`
                    body {
                        background: #fff;
                        color: #000;
                    }
                `}
            </style>
        </>
    )
}

export default AdminLayout
