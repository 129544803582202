import { api_url } from "Config";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type FetchResponseType = {
    status: "failed" | "success";
    code: number;
    message: string;
}

const AdminPasswordChange: React.FC = () => {
    const [loginId, setLoginId] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [resultMessage, setResultMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSendCode = async () => {
        setIsSuccess(false);
        try {
            setIsLoading(true);
            const res = await fetch(`${api_url}/admin-password-change.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    login_id: loginId
                })
            });

            const data = await res.json() as FetchResponseType;

            if (data.code !== 200) {
                throw new Error(data.message);
            }

            // completed
            setIsSuccess(true);
            setResultMessage("");

        } catch (error) {
            console.error(error);
            setIsSuccess(false);
            if (typeof(error) === "string") {
                setResultMessage(error);
            } else if (error instanceof Error) {
                setResultMessage(error.message);
            } else {
                setResultMessage("予期せぬエラーが発生しました。時間をおいてお試しください");
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="admin center-container">
            <main className="login">
                <header>
                    <h1>パスワード変更</h1>
                </header>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isSuccess ? (
                    <div>
                        <p>パスワードリセットのURLをメールで送信しました。<br />
                        もし間違っていた場合はメールが送信されません。</p>
                        <p>リセットURLの有効期限は15分です。15分を超えた場合は再度やり直してください。</p>
                    </div>
                ) : (
                    (
                        <>
                            <div className="login-form-content">
                                {resultMessage && (
                                    <p>{resultMessage}</p>
                                )}
                                <p>
                                    <label>ログインID</label>
                                    <input
                                        type="email"
                                        value={loginId}
                                        onChange={(e) => (setLoginId(e.target.value))}
                                    />
                                </p>
                            </div>
                            <div>
                                <button className="login-button" onClick={handleSendCode}>コードを送信</button>
                            </div>
                        </>
                    )
                )}
            </main>
            <ul className="login-footer">
                <li><Link to="/">サイトを見る</Link></li>
                <li><Link to="/admin/login">ログイン画面を開く</Link></li>
            </ul>
        </div>
    )
}

export default AdminPasswordChange
