export const google_map_api_key = "AIzaSyC5xkaLC2e3ERBxh0HP0g3OhGdrJ0CQRpw";
export const instagram_account_id = "Ct8hwTdSdpS";
export const instagram_account_name = "personalgym_serena_official";
export const instagram_account_title = "パーソナルジム セレナ(@personalgym_serena_official)";
export const tiktok_account_name = 'gym_serena';
export const tiktok_account_id = 'gym_serena';
export const hashimoto_instagram_link = 'https://www.instagram.com/takuya_hashimoto_0131/';
export const line_link = "https://line.me/ti/p/nw9Tp86ryF";
export const api_url = "https://personalgym-serena.com/api";
export const token_key = "serena_t";
