import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import AdminUserLayout from "layouts/AdminUserLayout";
import { api_url } from "Config";
import SanitizeInput from "utils/SanitizeInput";
import AdminInputError from "components/AdminInputError";
import AdminModal from "components/AdminModal";

interface ReserveData {
    id: number;
    code: string;
    plan_id: number;
    plan_name: string;
    date: string;
    time: string;
    name: string | null;
    phone: string | null;
    email: string | null;
    note: string | null;
    status: "1" | "0";
    updated_by: string | null;
    updated_at: string;
    created_at: string;
}

interface ReserveResponse {
    status: "success" | "failed";
    code: number;
    data: ReserveData;
    message?: string;
    history?: ReserveData[] | null;
}

interface FormData {
    id: number;
    name: string;
    phone: string;
    email: string;
    note: string | null;
}

interface FormErrors {
    name?: string;
    phone?: string;
    email?: string;
    note?: string;
}

interface EditResponseSuccess {
    status: "success";
    code: number;
    message: string;
}

interface EditResponseError {
    status: "failed";
    code: number;
    message: string;
    errors?: FormErrors;
}

const AdminReserveEdit: React.FC = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [successMessage, setSuccessMessage] = useState<string>("");
    const [reserveData, setReserveData] = useState<ReserveData | null>(null);
    const [formData, setFormData] = useState<FormData>({
        id: Number(id),
        name: "",
        phone: "",
        email: "",
        note: "",
    } as FormData);
    const [formErrors, setFormErrors] = useState<FormErrors>({} as FormErrors);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setErrorMessage("");

            try {
                const res = await fetch(`${api_url}/admin-reserve.php?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                });

                const data = await res.json() as ReserveResponse;
                setReserveData(data.data);

                setFormData({
                    id: Number(id),
                    name: data.data.name,
                    phone: data.data.phone,
                    email: data.data.email,
                    note: data.data.note,
                } as FormData);

            } catch (error) {
                if (typeof(error) === "string") {
                    setErrorMessage(error);
                } else if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage("接続エラーが発生しました。時間を置いてからお試しください");
                }
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [setIsLoading, setErrorMessage, setReserveData, id]);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            setErrorMessage("");
            setSuccessMessage("");
            setFormErrors({} as FormErrors);

            // validation
            let hasErrors = false;
            const newFormErrors: FormErrors = {};

            if (!formData.name || formData.name === "") {
                newFormErrors.name = "名前が入力されていません";
                hasErrors = true;
            }
            if (!formData.phone || formData.phone === "") {
                newFormErrors.phone = "電話番号が入力されていません";
                hasErrors = true;
            }

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!formData.email || formData.email === "") {
                newFormErrors.email = "メールアドレスが入力されていません";
                hasErrors = true;
            } else if (!emailRegex.test(formData.email)) {
                newFormErrors.email = "正しいメールアドレスを入力してください";
                hasErrors = true;
            }

            setFormErrors(newFormErrors);

            if (hasErrors) {
                return false;
            }

            // send
            const res = await fetch(`${api_url}/admin-reserve.php`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(formData),
            });

            const data = await res.json() as EditResponseSuccess | EditResponseError;

            if (data.status === "success") {
                setSuccessMessage(data.message);
            } else {
                setErrorMessage(data.message);
                if (data.errors) {
                    setFormErrors(data.errors as FormErrors);
                }
            }
        } catch (error) {
            if (typeof(error) === "string") {
                setErrorMessage(error);
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("予期せぬエラーが発生しました。時間をおいてお試しください");
            }
        } finally {
            setIsLoading(false);
        }
        return false;
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((previousFormData) => ({
            ...previousFormData,
            [name]: value,
        } as FormData));
    }

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData((previousFormData) => ({
            ...previousFormData,
            [name]: value,
        } as FormData));
    }

    const handleInfoButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        navigate(`/admin/reserve/${id}`);
    }

    return (
        <AdminUserLayout>
            <h1>予約編集</h1>
            {isLoading && <div className="loading"><AiOutlineLoading3Quarters /></div>}
            {!isLoading && errorMessage && (
                <div className="error">{errorMessage}</div>
            )}
            {!isLoading && reserveData && (
                <>
                    <div className="reserve-header">
                        <h2>予約情報</h2>
                    </div>
                    <div className="data-view">
                        <dl className="data-item">
                            <dt>ID</dt>
                            <dd>{id}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>予約コード</dt>
                            <dd>{SanitizeInput(reserveData.code)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>プラン</dt>
                            <dd>{SanitizeInput(reserveData.plan_name)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>予約日時</dt>
                            <dd>{SanitizeInput(`${reserveData.date} ${reserveData.time}`)}</dd>
                        </dl>
                    </div>

                    <h2>予約者情報</h2>
                    <div className="data-view">
                        <dl className="data-item">
                            <dt>氏名</dt>
                            <dd>
                                <input
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                />
                                {formErrors.name && <AdminInputError>{formErrors.name}</AdminInputError>}
                            </dd>
                        </dl>
                        <dl className="data-item">
                            <dt>電話番号</dt>
                            <dd>
                                <input
                                    type="tel"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                />
                                {formErrors.phone && <AdminInputError>{formErrors.phone}</AdminInputError>}
                            </dd>
                        </dl>
                        <dl className="data-item">
                            <dt>メールアドレス</dt>
                            <dd>
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                {formErrors.email && <AdminInputError>{formErrors.email}</AdminInputError>}
                            </dd>
                        </dl>
                        <dl className="data-item">
                            <dt>ご要望</dt>
                            <dd>
                                <textarea
                                    name="note"
                                    value={formData.note ?? ""}
                                    onChange={handleTextAreaChange}
                                ></textarea>
                                {formErrors.note && <AdminInputError>{formErrors.note}</AdminInputError>}
                            </dd>
                        </dl>
                    </div>

                    <div className="buttons">
                        <button onClick={handleSubmit} className="primary">変更する</button>
                    </div>

                    <div style={{height: '3rem'}}></div>
                    
                    {successMessage && <AdminModal>
                        <>
                            {successMessage}
                            <div className="buttons">
                                <button onClick={handleInfoButton}>予約情報に戻る</button>
                            </div>
                        </>    
                    </AdminModal>}
                </>
            )}
        </AdminUserLayout>
    )
}

export default AdminReserveEdit