import 'assets/scss/components/footer.scss';
import logo from 'assets/images/logo.svg';

const Footer = () => {
    return (
        <>
            <footer className='footer'>
                <p className='footer--logo fadeload'>
                    <img src={logo} alt="SERENA" loading='lazy' />
                </p>
                <p className='company'>株式会社SHIKANO<br />
                〒150-0043 東京都渋谷区道玄坂1-2-3 渋谷フクラス17F</p>
                <p className="copyright">Copyright © Personal gym SERENA</p>
            </footer>
        </>
    )
};

export default Footer;