import { google_map_api_key } from "Config";
import 'assets/scss/components/map.scss';

const Map = () => {
    const mapSrc = `https://www.google.com/maps/embed/v1/place?q=place_id:ChIJzcywOceNGGAR5wxvLqFDwGc&key=${google_map_api_key}`;
    return (
        <>
            <section className="map fadeload">
                <iframe title="Map" src={mapSrc}></iframe>
            </section>
        </>
    );
};

export default Map;