import React from "react";

interface CalendarDateProps {
    date: string;
}

const wJaList = ['日', '月', '火', '水', '木', '金', '土'];

export const DisplayDate = (dateString: string): string => {
    const date = new Date(dateString);
    const y = date.getFullYear();
    const m = String(date.getMonth() + 1).padStart(2, '0');
    const d = String(date.getDate()).padStart(2, '0');
    const w = date.getDay();
    const wja = wJaList[w];

    return `${y}年${m}月${d}日(${wja})`;
}

export const InputFieldDate = (dateObject: Date): string => {
    const y = dateObject.getFullYear();
    const m = String(dateObject.getMonth() + 1).padStart(2, '0');
    const d = String(dateObject.getDate()).padStart(2, '0');
    return `${y}-${m}-${d}`;
};

const CalendarDate: React.FC<CalendarDateProps> = (props: CalendarDateProps) => {
    const date = new Date(props.date);
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const w = date.getDay();
    const wja = wJaList[w];
    let c = "week";
    if (w === 0) c += " ft-red";
    if (w === 6) c += " ft-blue";

    return (
        <div className="calendar-date">
            <span className="day">{m}/{d}</span>
            <span className={c}>({wja})</span>
        </div>
    )
}

export default CalendarDate;
