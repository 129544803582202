import { api_url } from "Config";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

const AdminProtected: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuth = async () => {
            try {
                const res = await fetch(`${api_url}/admin-auth.php`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!res.ok) {
                    setErrorMessage('Access denined');
                    setTimeout(() => {
                        navigate('/admin/login');
                    }, 2000);
                    return;
                }

                setErrorMessage('');
            } catch (error) {
                setErrorMessage('Unknown error occurred');
                // setTimeout(() => {
                //     navigate('/admin/login');
                // }, 2000);
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();
    }, [navigate]);

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (errorMessage) {
        return <div>{errorMessage}</div>
    }

    return <Outlet />
}

export default AdminProtected
