import React from "react";
import AdminUserLayout from "layouts/AdminUserLayout";

const AdminReserve: React.FC = () => {
    return (
        <AdminUserLayout>
            <>
                <h1>予約登録</h1>
                <p>お客様と同じページからご予約ください。<br />
                その際お客様にメール通知したくない場合は、管理者のメールアドレスを入力してください。</p>
                <a className="primary" target="_blank" href="/reserve">予約フォームを開く</a>
            </>
        </AdminUserLayout>
    )
}

export default AdminReserve
