import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api_url } from "Config";
import { AdminLoginResponseSchema } from "schemas/admin";
import { useAdminAuth } from "components/AdminAuthContext";

const AdminLogin: React.FC = () => {
    const [loginId, setLoginId] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [resultMessage, setResultMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { login } = useAdminAuth();
    const navigate = useNavigate();

    const handleLogin = async () => {
        try {
            if (loginId.length < 10) {
                setResultMessage('Invalid login id');
                return;
            }
            if (password.length < 1) {
                setResultMessage('Invalid password');
                return;
            }

            setIsLoading(true);

            const res = await fetch(`${api_url}/admin-login.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "login_id": loginId,
                    "password": password,
                }),
                credentials: 'include'
            });

            if (!res.ok) {
                setResultMessage('Login failed');
                return;
            }

            const data: AdminLoginResponseSchema = await res.json();

            if (data.error) {
                setResultMessage(data.error);
            } else if (data.token) {
                // data.token;
                login();
                navigate('/admin');
                return;
                // localStorage.setItem(token_key, data.token);
            } else {
                setResultMessage('unknown error occurred');
            }

        } catch (error) {
            setResultMessage('unknown error occurred');
        } finally {
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return (
            <>Loading</>
        )
    }

    return (
        <div className="admin center-container">            
            <main className="login">
                <header>
                    <h1>予約管理ログイン</h1>
                </header>
                {resultMessage && <div className="error-message">{resultMessage}</div>}
                <div className="login-form-content">
                    <p>
                        <label>ログインID</label>
                        <input
                            type="email"
                            value={loginId}
                            onChange={(e) => setLoginId(e.target.value)}
                        />
                    </p>
                    <p>
                        <label>パスワード</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </p>
                </div>
                <div>
                    <button className="login-button" onClick={handleLogin}>Login</button>
                </div>
            </main>
            <ul className="login-footer">
                <li><Link to="/">サイトを見る</Link></li>
                <li><Link to="/admin/password-change">パスワードを変更</Link></li>
            </ul>
        </div>
    )
}

export default AdminLogin
