import React, { ChangeEvent, FormEvent, useEffect, useState, useCallback } from "react";

import { api_url } from "Config";

import { FaCheck } from "react-icons/fa6";

import ClientLoading from "components/ClientLoading";
import CalendarDate, { DisplayDate } from "components/CalendarDate";
import ClientInputError from "components/ClientInputError";
import SanitizeInput from "utils/SanitizeInput";

import logo from "assets/images/logo.svg";

import 'assets/reserve/scss/style.scss';
import { Helmet } from "react-helmet-async";


type PlanItem = {
    id: number;
    name: string;
}
type PlanItemResponse = {
    status: "success" | "failed";
    code: number;
    message?: string | undefined;
    items?: PlanItem[] | undefined;
}

type DateItem = {
    date: string;
    start_time: string;
    status: "empty" | "reserved";
}
type DateItemResponse = {
    status: "success" | "failed";
    code: number;
    message?: string | undefined;
    items?: Record<string, DateItem[]> | undefined;
    prev?: string | undefined | null;
    next?: string | undefined | null;
}

interface OrderData {
    plan: string;
    date: string;
    time: string;
    name: string;
    tel: string;
    email: string;
    message: string;
}

interface OrderErrors {
    plan?: string;
    date?: string;
    time?: string;
    name?: string;
    tel?: string;
    email?: string;
    message?: string;
}

interface ReserveRequestData {
    plan_id: string;
    plan_name: string;
    reserve_date: string;
    reserve_start_time: string;
    name: string;
    phone: string;
    email: string;
    note: string;
}

interface ReserveResponseErrorData {
    plan_id?: string;
    plan_name?: string;
    reserve_date?: string;
    reserve_start_time?: string;
    name?: string;
    phone?: string;
    email?: string;
    note?: string;
}
interface ReserveResponseData {
    status: boolean;
    errors: ReserveResponseErrorData,
    reserveCode?: string;
    message: string;
}

const Reserve: React.FC = () => {
    const [step, setStep] = useState<1 | 2 | 3 | "fin">(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [plans, setPlans] = useState<PlanItem[]>([]);
    const [dates, setDates] = useState<Record<string, DateItem[]>>({});
    const [prev, setPrev] = useState<string>("");
    const [next, setNext] = useState<string>("");

    const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
    const [selectedPlanName, setSelectedPlanName] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<string>("");
    const [selectedTime, setSelectedTime] = useState<string>("");
    const [reserveCode, setReserveCode] = useState<string>("");

    const [orderData, setOrderData] = useState<OrderData>({
        plan: "",
        date: "",
        time: "",
        name: "",
        tel: "",
        email: "",
        message: "",
    } as OrderData);

    const [orderErrors, setOrderErrors] = useState<OrderErrors>({} as OrderErrors);

    const fetchCalendarData = useCallback(async ($requestDate: string | null = null) => {
        setIsLoading(true);
        try {
            const res = await fetch(`${api_url}/calendar.php?plan_id=${selectedPlan}&start=${$requestDate ?? ""}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await res.json() as DateItemResponse;
            if (data.message) {
                throw new Error(data.message);
            }

            if (data.items && Object.keys(data.items).length > 0) {
                // OK
                setDates(data.items);
                setPrev(data.prev ?? "");
                setNext(data.next ?? "");
            } else {
                throw new Error("受付可能な予定がありません");
            }
        } catch (error) {
            if (typeof(error) === "string") {
                setErrorMessage(error);
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("時間を置いてからお試しください");
            }
        } finally {
            setIsLoading(false);
        }
    }, [selectedPlan]);

    useEffect(() => {
        const fetchPlanData = async () => {
            try {
                const res = await fetch(`${api_url}/plans.php`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                const data = await res.json() as PlanItemResponse;
                if (data.code !== 200) {
                    throw new Error(data.message);
                }

                if (typeof(data.message) === "string") {
                    setErrorMessage(data.message);
                } else if (typeof(data.items) !== "undefined") {
                    setPlans(data.items);
                    if (data.items.length > 0) {
                        setSelectedPlan(data.items[0].id);
                        setSelectedPlanName(data.items[0].name);
                    }
                } else {
                    setPlans([]);
                }

            } catch (error) {
                if (typeof(error) === "string") {
                    setErrorMessage(error);
                } else if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage("時間を置いてからお試しください");
                }
            } finally {
                setIsLoading(false);
            }
        }

        fetchPlanData();

        if (selectedPlan !== null) {
            fetchCalendarData();
        }
    }, [selectedPlan, fetchCalendarData]);

    const handlePlanChange = (id: number, name: string) => {
        setSelectedPlan(id);
        setSelectedPlanName(name);
    }

    const handleDateTimeSelect = (sd: string, st: string) => {
        setSelectedDate(sd);
        setSelectedTime(st);
        setOrderData((previousOrderData) => ({
            ...previousOrderData,
            date: sd,
            time: st,
        } as OrderData));
        setStep(2);
    }

    const handleToReset = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setSelectedDate("");
        setSelectedTime("");
        setOrderData((previousOrderData) => ({
            ...previousOrderData,
            date: "",
            time: "",
        } as OrderData));
        setOrderErrors({} as OrderErrors);
        setReserveCode("");
        setStep(1);
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setOrderData((previousOrderData) => ({
            ...previousOrderData,
            [name]: value,
        } as OrderData));
    };

    const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setOrderData((previousOrderData) => ({
            ...previousOrderData,
            [name]: value,
        } as OrderData));
    }

    const handleToConfirm = async () => {
        let hasErrors = false;
        const newOrderErrors: OrderErrors = {};
        
        if (!orderData.date || orderData.date === "") {
            newOrderErrors.date = "日付が選択されていません";
            hasErrors = true;
        }
        if (!orderData.time || orderData.time === "") {
            newOrderErrors.time = "時間が選択されていません";
            hasErrors = true;
        }
        if (!orderData.name || orderData.name === "") {
            newOrderErrors.name = "名前が入力されていません";
            hasErrors = true;
        }

        const phoneRegex = /^(\d{2,4}-?\d{2,4}-?\d{3,4})$/;
        if (!orderData.tel || orderData.tel === "") {
            newOrderErrors.tel = "電話番号が入力されていません";
            hasErrors = true;
        } else if (!phoneRegex.test(orderData.tel)) {
            newOrderErrors.tel = "正しい電話番号を入力してください";
            hasErrors = true;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!orderData.email || orderData.email === "") {
            newOrderErrors.email = "メールアドレスが入力されていません";
            hasErrors = true;
        } else if (!emailRegex.test(orderData.email)) {
            newOrderErrors.email = "正しいメールアドレスを入力してください";
            hasErrors = true;
        }
    
        setOrderErrors(newOrderErrors);
    
        if (hasErrors) {
            console.error(newOrderErrors);
            return false;
        }
    
        setStep(3);
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            setIsLoading(true);
            setErrorMessage("");
            const data: ReserveRequestData = {
                plan_id: selectedPlan!.toString(),
                plan_name: selectedPlanName!,
                reserve_date: orderData.date,
                reserve_start_time: orderData.time,
                name: orderData.name,
                phone: orderData.tel,
                email: orderData.email,
                note: orderData.message ?? ""
            };
            const res = await fetch(`${api_url}/reserve.php`, {
                method: "POST",
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(data),
            });

            const responseData = await res.json() as ReserveResponseData;
            if (!responseData.status) {
                // エラーあり
                setErrorMessage(responseData.message);
                const newOrderErrors: OrderErrors = {};
                for (const [key, value] of Object.entries(responseData.errors)) {
                    if (value) {
                        if (key === "phone") {
                            newOrderErrors.tel = value;
                        } else {
                            newOrderErrors[key as keyof OrderErrors] = value;
                        }
                    }
                }
                setOrderErrors(newOrderErrors);
            } else {
                // 成功
                setReserveCode(responseData.reserveCode!);
                setStep("fin");
            }

        } catch (error) {
            if (typeof error === "string") {
                setErrorMessage(error);
            } else if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("エラーが発生しました。申し訳ありませんが時間をおいてからお試しください");
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Helmet>
                <title>ご予約フォーム | 自然とモテる身体を実現する SERENA</title>
                <meta name="description" content="最先端のトレーニング技術を駆使した新宿御苑・四谷三丁目のパーソナルトレーニングジム SERENA(セレナ)" />
            </Helmet>
            <div className="client-reserve crs">
                <div>
                    <header>
                        <h1><img className="crs-logo" src={logo} alt="パーソナルジム SERENA" /></h1>
                        <h2>
                            <span className="title">ご予約フォーム</span>
                        </h2>
                    </header>
                    <ol className="list-steps">
                        <li className={step === 1 ? "target" : ""}>{step === 1 ? <span className="number">1</span> : <span className="check"><FaCheck /></span>}<span className="text">日時を選ぶ</span></li>
                        <li className="step"></li>
                        <li className={step === 2 ? "target" : ""}>{step === 1 || step === 2 ? <span className="number">2</span> : <span className="check"><FaCheck /></span>}<span className="text">お客様情報の入力</span></li>
                        <li className="step"></li>
                        <li className={step === 3 ? "target" : ""}>{step !== 'fin' ? <span className="number">3</span> : <span className="check"><FaCheck /></span>}<span className="text">ご予約内容の確認</span></li>
                    </ol>
                </div>

                <main>
                    {errorMessage && (
                        <>{errorMessage}</>
                    )}
                    <div className="steps">
                        <div className="step step1">
                            <span className="current">{step.toString()}</span>
                            <span className="max">3</span>
                        </div>
                        <h3>
                            {step === 1 && "日時を選ぶ"}
                            {step === 2 && "お客様情報の入力"}
                            {step === 3 && "ご予約内容の確認"}
                        </h3>
                    </div>
                    {step === 1 && (
                        <>
                            <div className="input-wrap">
                                <p className="title">ご希望のメニュー</p>
                                {plans.map((item, number) => (
                                    <div className="menu-item" key={`menu_${number}`}>
                                        <input
                                            type="radio"
                                            name="menu"
                                            value={item.id.toString()}
                                            id={`menu_${item.id}`}
                                            checked={selectedPlan === item.id}
                                            onChange={() => handlePlanChange(item.id, item.name)}
                                        />
                                        <label htmlFor={`menu_${item.id}`}>{item.name}</label>
                                    </div>
                                ))}
                                {dates && Object.keys(dates).length > 0 && (
                                    <div>
                                        <div className="prev-next">
                                            {prev ? <button onClick={() => fetchCalendarData(prev)}>前の週</button> : <div></div>}
                                            {next ? <button onClick={() => fetchCalendarData(next)}>次の週</button> : <div></div>}
                                        </div>
                                        <div className="calendar">
                                            <div className="calendar-header">
                                                <div></div>
                                                {Object.keys(dates).map((d: string, index: number) => (
                                                    <CalendarDate key={`calendar-date-${index}`} date={d} />
                                                ))}
                                            </div>
                                            <div className="calendar-body">
                                                <div className="col">
                                                    <div>11:00</div>
                                                    <div>12:00</div>
                                                    <div>13:00</div>
                                                    <div>14:00</div>
                                                    <div>15:00</div>
                                                    <div>16:00</div>
                                                    <div>17:00</div>
                                                    <div>18:00</div>
                                                    <div>19:00</div>
                                                    <div>20:00</div>
                                                    <div>21:00</div>
                                                </div>
                                                {Object.keys(dates).map((d: string, index: number) => (
                                                    <div key={index} className="col">
                                                        {dates[d].map((item: DateItem, i: number) => (
                                                            <div key={`time_${index}_${i}`}>
                                                                <button onClick={() => handleDateTimeSelect(d, item.start_time)} className="date-time-button" disabled={item.status === "reserved"}>{item.status === "empty" ? "⚪︎" : "×"}</button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <div className="input-wrap">
                                <p className="title">お客様情報の入力</p>
                                {orderErrors.date && <ClientInputError>{orderErrors.date}</ClientInputError>}
                                {orderErrors.time && <ClientInputError>{orderErrors.time}</ClientInputError>}
                                <div className="user-info-form">
                                    <dl>
                                        <dt>お名前 <span className="isRequire require">必須</span></dt>
                                        <dd>
                                            <input
                                                type="text"
                                                name="name"
                                                value={orderData.name}
                                                onChange={handleInputChange}
                                                placeholder="山田花子"
                                            />
                                            {orderErrors.name && <ClientInputError>{orderErrors.name}</ClientInputError>}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>電話番号 <span className="isRequire require">必須</span></dt>
                                        <dd>
                                            <input
                                                type="tel"
                                                name="tel"
                                                value={orderData.tel}
                                                onChange={handleInputChange}
                                                placeholder="09011112222"
                                            />
                                            {orderErrors.tel && <ClientInputError>{orderErrors.tel}</ClientInputError>}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>メールアドレス <span className="isRequire require">必須</span></dt>
                                        <dd>
                                            <input
                                                type="email"
                                                name="email"
                                                value={orderData.email}
                                                onChange={handleInputChange}
                                                placeholder="info@personalgym-serena.com"
                                            />
                                            {orderErrors.email && <ClientInputError>{orderErrors.email}</ClientInputError>}
                                        </dd>
                                    </dl>
                                    <dl>
                                        <dt>ご要望 <span className="isRequire free">任意</span></dt>
                                        <dd>
                                            <textarea
                                                name="message"
                                                value={orderData.message}
                                                onChange={handleTextAreaChange}
                                                placeholder="特に気になる部位や症状、痛み、お悩みなどがあればご記入ください"
                                            ></textarea>
                                            {orderErrors.message && <ClientInputError>{orderErrors.message}</ClientInputError>}
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </>
                    )}
                    {step === 3 && (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="input-wrap confirmation">
                                    <dl>
                                        <dt>プラン</dt>
                                        <dd>{SanitizeInput(selectedPlanName!)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>日時</dt>
                                        <dd>{SanitizeInput(DisplayDate(selectedDate))} {SanitizeInput(selectedTime)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>お名前</dt>
                                        <dd>{SanitizeInput(orderData.name)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>電話番号</dt>
                                        <dd>{SanitizeInput(orderData.tel)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>メールアドレス</dt>
                                        <dd>{SanitizeInput(orderData.email)}</dd>
                                    </dl>
                                    <dl>
                                        <dt>ご要望</dt>
                                        <dd>{SanitizeInput(orderData.message)}</dd>
                                    </dl>
                                </div>
                                <div className="buttons-column confirmation">
                                    <button className="primary" type="submit">予約する</button>
                                    <button className="cancel" type="button" onClick={handleToReset}>最初からやり直す</button>
                                </div>
                            </form>
                        </>
                    )}
                    {step !== 3 && step !== "fin" && (
                        <div className="bottom-status">
                            <div className="bottom-status-container">
                                <div className="bottom-status__left">
                                    {selectedPlanName && (
                                        <div>{SanitizeInput(selectedPlanName)}</div>
                                    )}
                                    {(selectedDate && selectedTime) && (
                                        <div>{SanitizeInput(DisplayDate(selectedDate))} {SanitizeInput(selectedTime)}</div>
                                    )}
                                </div>
                                {step === 2 && (
                                    <div className="bottom-status__right buttons">
                                        <button className="btn cancel" onClick={handleToReset}>最初からやり直す</button>
                                        <button className="btn primary" onClick={handleToConfirm}>ご予約内容の確認へ</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {step === "fin" && (
                        <>
                            <div className="fin">
                                <h2>予約完了</h2>
                                <p>予約コード: {reserveCode}</p>
                                <p>ご予約の変更等はお電話にてご連絡ください</p>
                            </div>
                        </>
                    )}
                    {isLoading && (
                        <ClientLoading />
                    )}
                </main>
            </div>
            <style>
                {`
                    body {
                        background: #efefef;
                        color: #000;
                    }
                `}
            </style>
        </>
    )
}

export default Reserve
