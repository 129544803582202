import React from "react";
import { Helmet } from "react-helmet-async";

const PageNotFound: React.FC = () => (
    <>
        <Helmet>
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <main>
            <p>Page not found!</p>
        </main>
    </>
)

export default PageNotFound
