import logo from 'assets/images/logo.svg';
import 'assets/scss/components/logo.scss';

const Logo = () => {
    return (
        <>
            <div className="logo">
                <img src={logo} alt="SERENA" loading='eager' />
            </div>
        </>
    )
}

export default Logo;