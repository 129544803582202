import 'assets/scss/components/naturaly.scss';
import catchcopy1x from 'assets/images/naturaly/catchcopy@1x.png';
import catchcopy2x from 'assets/images/naturaly/catchcopy@2x.png';
import free from 'assets/images/naturaly/offer-free.png';
import training from 'assets/images/naturaly/offer-training.png';

const Natulary = () => {
    return (
        <>
            <section className="section natulary">
                <div className="section--content">
                    <div className='catch'>
                        <div className='catch--picture fadeload'>
                            <picture>
                                <source media='(min-width: 800px)' srcSet={catchcopy2x} />
                                <source media='(min-width: 400px)' srcSet={catchcopy1x} />
                                <img src={catchcopy1x} alt="自然とモテる身体を実現する" loading='eager' />
                            </picture>
                        </div>
                        <div className='catch--copy copy fadeload'>
                            <div className='copy--text'>
                                <p>新宿御苑前 徒歩4分<br />
                                SERENAでは最先端のトレーニング技術を駆使し「楽しく最短で結果を出す」を目指します。<br />
                                完全個室・マンツーマン指導のため、女性やトレーニング初心者のお客様にも安心してご利用いただけます。<br />
                                実績のあるトレーナー陣が、あなたの人生をより一層輝かせるお手伝いをいたします。</p>
                            </div>
                        </div>
                    </div>

                    <div className='offer'>
                        <p className='offer--free fadeload'><img src={free} alt="月会費&入会金0円" loading='eager' /></p>
                        <p className='offer--training fadeload'><img src={training} alt="トレーニングイメージ" loading='eager' /></p>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Natulary;