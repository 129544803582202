import { api_url } from "Config";
import React, { createContext, useContext, useState, useEffect, ReactNode} from "react";

interface AdminAuthContextType {
    isAuthenticated: boolean;
    login: () => void;
    logout: () => void;
}

const AdminAuthContext = createContext<AdminAuthContextType | undefined>(undefined);

export const useAdminAuth = () => {
    const context = useContext(AdminAuthContext);
    if (!context) {
        throw new Error("useAdminAuth must be used within an AdminAuthProvider");
    }
    return context;
}

export const AdminAuthProvider = ({ children }: { children: ReactNode }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    const checkAuth = async () => {
        try {
            const res = await fetch(`${api_url}/admin-auth.php`, {
                method: 'GET',
                credentials: 'include'
            });

            if (res.ok) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        } catch (error) {
            setIsAuthenticated(false);
        }
    };

    useEffect(() => {
        if (document.location.pathname !== '/admin/login') {
            checkAuth();
        }
    }, []);

    const login = () => {
        setIsAuthenticated(true);
    };

    const logout = async () => {
        try {
            const res = await fetch(`${api_url}/admin-logout.php`, {
                method: 'POST',
                credentials: 'include'
            });

            if (res.ok) {
                setIsAuthenticated(false);
            }
        } catch (error) {
            console.error("Failed to logout: ", error);
        }
    }

    return (
        <AdminAuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AdminAuthContext.Provider>
    )
}
