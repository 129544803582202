import { hashimoto_instagram_link } from 'Config';
import 'assets/scss/components/speciality.scss';
import imgPurpose from 'assets/images/speciality/purpose.png';
import imgWants from 'assets/images/speciality/wants.png';
import imgInstagram from 'assets/images/sns/icon-instagram.png';

const Speciality = () => {
    const openInstagram = () => {
        window.open(hashimoto_instagram_link);
        return false;
    }

    return (
        <>
            <section className="section speciality">
                <div className="section--container">
                    <p className='purposeimg fadeload'><img src={imgPurpose} alt="一人一人" loading='eager' /></p>
                    <div className='textspace'>
                        <div className='wants'>
                            <p className='purpose fadeload'>SERENAでは一人一人の目的に<br />
                            合わせたトレーニングプログラムを<br />
                            作成し適切なアドバイスを行います。</p>
                            <p className='wantsimg fadeload'><img src={imgWants} alt="バストアップしたい, 足を細くしたい, くびれが欲しい、 ヒップアップしたい, 姿勢を改善したい, 食事指導をしてほしい" loading='eager' /></p>
                        </div>

                        <div className='profile fadeload'>
                            <div className='profile--wrap'>
                                <p className='omakase fadeload'>お任せください!</p>
                                <div className='profile-container'>
                                    <h3 className='profile-catch fadeload'>
                                        <span className='line'>皆様の理想の</span>
                                        <span className='line'>ボディへのお手伝いを</span>
                                        <span className='line'>指導いたします！</span>
                                    </h3>
                                    <div className='profile-text fadeload'>
                                        <p className='aword'>ベストボディジャパン<br />
                                        パーソナルトレーナー大会 2位</p>
                                        <p className='incharge'>SERENAトレーナー</p>
                                        <p className='name'>橋本拓也</p>
                                        <p><button className='link-to-instagram' onClick={openInstagram}><img src={imgInstagram} alt="Instagram" loading='eager' /></button></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Speciality;