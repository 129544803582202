export const fadeLoad = () => {
    const targets = document.querySelectorAll('.fadeload') as NodeListOf<HTMLDivElement> | null;
    targets?.forEach(e => {
        let observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active');
                    observer.unobserve(entry.target);
                }
            });
        });

        observer.observe(e);
    });
};
