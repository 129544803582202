import React from "react";

interface ClientInputErrorProps {
    children?: React.ReactNode;
}

const ClientInputError: React.FC<ClientInputErrorProps> = (props: ClientInputErrorProps) => {
    if (!props.children) {
        return <></>
    }
    
    return (
        <p className="inputError">{props.children}</p>
    )
}

export default ClientInputError
