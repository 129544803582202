import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import AdminUserLayout from "layouts/AdminUserLayout";
import { api_url } from "Config";
import SanitizeInput from "utils/SanitizeInput";
import AdminModal from "components/AdminModal";

interface ReserveData {
    id: number;
    code: string;
    plan_id: number;
    plan_name: string;
    date: string;
    time: string;
    name: string | null;
    phone: string | null;
    email: string | null;
    note: string | null;
    status: "1" | "0";
    updated_by: string | null;
    updated_at: string;
    created_at: string;
}

interface ReserveResponse {
    status: "success" | "failed";
    code: number;
    data: ReserveData;
    message?: string;
    history?: ReserveData[] | null;
}

interface DeleteResponse {
    status: "success" | "failed";
    code: number;
    message: string;
}

const AdminReserveInfo: React.FC = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [reserveData, setReserveData] = useState<ReserveData | null>(null);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<string>("");

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setErrorMessage("");

            try {
                const res = await fetch(`${api_url}/admin-reserve.php?id=${id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include',
                });

                const data = await res.json() as ReserveResponse;
                setReserveData(data.data);
            } catch (error) {
                if (typeof(error) === "string") {
                    setErrorMessage(error);
                } else if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage("接続エラーが発生しました。時間を置いてからお試しください");
                }
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [setIsLoading, setErrorMessage, setReserveData, id]);

    const handleEditButton = () => {
        navigate(`/admin/reserve/edit/${id}`);
    }

    const handleDeleteButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsDelete(true);
    }

    const handleDeleteCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsDelete(false);
    }

    const handleDeleteDo = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        try {
            setIsDeleting(true);
            setDeleteErrorMessage("");
            const res = await fetch(`${api_url}/admin-reserve.php`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({id: id}),
            });

            const data = await res.json() as DeleteResponse;
            if (data.status === "failed") {
                setDeleteErrorMessage(data.message);
            } else {
                navigate('/admin/reserved');
            }
        } catch (error) {
            if (typeof(error) === "string") {
                setDeleteErrorMessage(error);
            } else if (error instanceof Error) {
                setDeleteErrorMessage(error.message);
            } else {
                setDeleteErrorMessage("予期せぬエラーが発生しました。時間をおいてお試しください");
            }
        } finally {
            setIsDeleting(false);
        }
    }

    return (
        <AdminUserLayout>
            <h1>予約一覧</h1>
            {isLoading && <div className="loading"><AiOutlineLoading3Quarters /></div>}
            {!isLoading && errorMessage && (
                <div className="error">{errorMessage}</div>
            )}
            {!isLoading && reserveData && (
                <>
                    <div className="reserve-header">
                        <h2>予約情報</h2>
                        <div className="buttons">
                            <button className="primary" onClick={handleEditButton}>編集</button>
                            <button className="danger" onClick={handleDeleteButton}>削除</button>
                        </div>
                    </div>
                    <div className="data-view">
                        <dl className="data-item">
                            <dt>ID</dt>
                            <dd>{id}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>予約コード</dt>
                            <dd>{SanitizeInput(reserveData.code)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>プラン</dt>
                            <dd>{SanitizeInput(reserveData.plan_name)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>予約日時</dt>
                            <dd>{SanitizeInput(`${reserveData.date} ${reserveData.time}`)}</dd>
                        </dl>
                    </div>

                    <h2>予約者情報</h2>
                    <div className="data-view">
                        <dl className="data-item">
                            <dt>氏名</dt>
                            <dd>{SanitizeInput(reserveData.name)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>電話番号</dt>
                            <dd>{SanitizeInput(reserveData.phone)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>メールアドレス</dt>
                            <dd>{SanitizeInput(reserveData.email)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>ご要望</dt>
                            <dd>{SanitizeInput(reserveData.note)}</dd>
                        </dl>
                    </div>

                    <div className="data-view">
                        <dl className="data-item">
                            <dt>更新情報</dt>
                            <dd>{SanitizeInput(reserveData.updated_by)}: {SanitizeInput(reserveData.updated_at)}</dd>
                        </dl>
                        <dl className="data-item">
                            <dt>予約日</dt>
                            <dd>{SanitizeInput(reserveData.created_at)}</dd>
                        </dl>
                    </div>

                    {isDelete && (
                        <AdminModal>
                            <>
                                <p>本当に削除しますか？<br />
                                この操作は取り消せません</p>
                                {deleteErrorMessage && (
                                    <div className="error">{deleteErrorMessage}</div>
                                )}
                                <div className="buttons">
                                    <button className="cancel" onClick={handleDeleteCancel}>キャンセル</button>
                                    <button className="danger" onClick={handleDeleteDo}>削除</button>
                                </div>
                            </>
                        </AdminModal>
                    )}
                    {isDeleting && (
                        <AdminModal>
                            <>
                                <p>削除しています</p>
                            </>
                        </AdminModal>
                    )}
                </>
            )}
        </AdminUserLayout>
    )
}

export default AdminReserveInfo;
