import { useState, useEffect } from 'react';
import { tiktok_account_id, tiktok_account_name } from 'Config';

const Tiktok = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    
    useEffect(() => {
        if (!isLoaded) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;

            const attr = document.createAttribute('src');
            attr.value = '//www.tiktok.com/embed.js';
            script.setAttributeNode(attr);

            const head = document.querySelector('head') as HTMLHeadElement | null;
            head!.appendChild(script);

            setIsLoaded(true);
        }
    }, [isLoaded]);

    const cite = `https://www.tiktok.com/@${tiktok_account_name}`;
    const link_href = `${cite}?refer=creator_embed`;

    return (
        <>
            <blockquote
                className='tiktok-embed'
                cite={cite}
                data-unique-id={tiktok_account_id}
                data-embed-type="creator"
                style={{
                    maxWidth: '100%',
                    minWidth: '150px',
                }}
            >
                <section>
                    <a
                        href={link_href}
                        target='_blank'
                        rel="noreferrer"
                    >@{tiktok_account_name}</a>
                </section>
            </blockquote>
        </>
    )
};

export default Tiktok;