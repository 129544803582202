import AdminModal from "components/AdminModal";
import BackDropPopup from "components/BackDropPopup";
import { api_url } from "Config";
import AdminUserLayout from "layouts/AdminUserLayout"
import React, { useCallback, useEffect, useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

interface TimeDetailData {
    status: "reserved" | "empty";
    reserve_id: number | null;
}

interface TimeData {
    [time: string]: TimeDetailData;
}
interface DateTimeData {
    [date: string]: TimeData;
}

interface ScheduleListResponseSuccess {
    status: "success";
    code: number;
    data: DateTimeData;
}

interface ScheduleListResponseFailed {
    status: "failed";
    code: number;
    message: string;
}

interface ScheduleAddResponseSuccess {
    status: "success";
    code: number;
    message: string;
}

interface ScheduleAddResponseFailed {
    status: "failed";
    code: number;
    message: string;
}

interface UpdateDateTimeData {
    date: string;
    time: string;
}

interface ScheduleDeleteResponseSuccess {
    status: "success";
    code: number;
    message: string;
}

interface ScheduleDeleteResponseFailed {
    status: "failed";
    code: number;
    message: string;
}

interface StopDateIsExistsResponseSuccessExists {
    status: "success";
    code: number;
    is_exists: true;
    id: number;
}

interface StopDateIsExistsResponseSuccessNotExists {
    status: "success";
    code: number;
    is_exists: false;
}

interface StopDateIsExistsResponseFailed {
    status: "failed";
    code: number;
    message: string;
}

interface DateAddResponseSuccess {
    status: "success";
    code: number;
    message: string;
}

interface DateAddResponseFailed {
    status: "failed";
    code: number;
    message: string;
}

interface DateDeleteResponseSuccess {
    status: "success";
    code: number;
}

interface DateDeleteResponseFailed {
    status: "failed";
    code: number;
    message: string;
}

const AdminReserveStopPerDateTime: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [scheduleData, setScheduleData] = useState<DateTimeData | null>(null);
    const [updateDateTime, setUpdateDateTime] = useState<UpdateDateTimeData | null>(null);
    const [updateDate, setUpdateDate] = useState<string | null>(null);
    const [confirmMessage, setConfirmMessage] = useState<string | null>(null);
    const [deleteConfirmMessage, setDeleteConfirmMessage] = useState<string | null>(null);
    const [dateAddConfirmMessage, setDateAddConfirmMessage] = useState<string | null>(null);
    const [dateDeleteConfirmMessage, setDateDeleteConfirmMessage] = useState<string | null>(null);
    const [dateDeleteId, setDateDeleteId] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | null>(null);
    const [dateErrorMessage, setDateErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    const fetchData = useCallback(async (year: string | null = null, month: string | null = null) => {
        setIsLoading(true);
        setScheduleData(null);

        try {
            const queryParams = new URLSearchParams();
            if (year !== null) queryParams.append('year', year);
            if (month !== null) queryParams.append('month', month);
            const res = await fetch(`${api_url}/admin-reserve-stop-per-datetime.php?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            });

            const data = await res.json() as ScheduleListResponseSuccess | ScheduleListResponseFailed;
            if (data.status === "failed") {
                throw data.message;
            }

            setScheduleData(data.data);

        } catch (error) {
            let message = "";
            if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === "string") {
                message = error;
            } else {
                message = "予期せぬエラーが発生しました";
            }
            setErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const addData = useCallback(async (date: string, time: string) => {
        setIsLoading(true);
        setConfirmMessage(null);
        setUpdateDateTime(null);

        try {
            const res = await fetch(`${api_url}/admin-reserve-stop-per-datetime.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    date: date,
                    time: time,
                }),
            });

            const data = await res.json() as ScheduleAddResponseSuccess | ScheduleAddResponseFailed;
            if (data.status === "failed") {
                // エラーポップアップ
                setErrorMessage(data.message);
            } else {
                // 完了したため、更新
                const year = (new Date(date).getFullYear).toString();
                const month = (new Date(date).getMonth() + 1).toString();
                fetchData(year, month);
            }
        } catch (error) {
            let message = "";
            if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === "string") {
                message = error;
            } else {
                message = "予期せぬエラーが発生しました";
            }
            setDeleteErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    }, [fetchData]);

    const deleteData = useCallback(async (date: string, time: string) => {
        setIsLoading(true);
        setDeleteConfirmMessage(null);
        setUpdateDateTime(null);

        try {
            const res = await fetch(`${api_url}/admin-reserve-stop-per-datetime.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({
                    date: date,
                    time: time,
                }),
            });

            const data = await res.json() as ScheduleDeleteResponseSuccess | ScheduleDeleteResponseFailed;

            if (data.status === "failed") {
                setDeleteErrorMessage(data.message);
            } else {
                // 完了したため画面更新
                const year = (new Date(date).getFullYear).toString();
                const month = (new Date(date).getMonth() + 1).toString();
                fetchData(year, month);
            }

        } catch (error) {
            let message = "";
            if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === "string") {
                message = error;
            } else {
                message = "予期せぬエラーが発生しました"
            }
            setDeleteErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    }, [fetchData]);

    const toStopTime = (date: string, time: string) => {
        setUpdateDateTime({date, time});
        const dt = new Date(`${date} ${time}`);
        const d = dt.toLocaleDateString('ja-JP', { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short'});
        const t = dt.toLocaleTimeString('ja-JP', { hour: 'numeric', 'minute': 'numeric'});
        const message = `${d} ${t}〜の予約受付を停止します`;
        setConfirmMessage(message);
    }

    const deleteStopTime = (date: string, time: string) => {
        setUpdateDateTime({date, time});
        const dt = new Date(`${date} ${time}`);
        const d = dt.toLocaleDateString('ja-JP', { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short'});
        const t = dt.toLocaleTimeString('ja-JP', { hour: 'numeric', 'minute': 'numeric'});
        const message = `${d} ${t}〜の停止設定を削除し、受付を再開します`;
        setDeleteConfirmMessage(message);
    }

    const toggleDate = async (date: string) => {
        if (isLoading) return;
        setUpdateDate(date);
        const dt = new Date(date);
        const d = dt.toLocaleDateString('ja-JP', { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short'});

        try {
            setIsLoading(true);

            // 削除可能な日か確認する
            const queryParams = new URLSearchParams();
            queryParams.append('date', date);
            const checkRes = await fetch(`${api_url}/admin-reserve-stop.php?${queryParams.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            const data = await checkRes.json() as StopDateIsExistsResponseSuccessExists | StopDateIsExistsResponseSuccessNotExists | StopDateIsExistsResponseFailed;
            if (data.status === "failed") {
                setDateErrorMessage(data.message);
                setUpdateDate(null);
                return;
            }

            if (data.is_exists) {
                const message = `${d}の予約受付を再開しますか？`;
                setDateDeleteId(data.id);
                setDateDeleteConfirmMessage(message);
                return;
            }

            const message = `${d}の予約受付を停止しますか？`;
            setDateAddConfirmMessage(message);

        } catch (error) {
            let message = "";
            if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === "string") {
                message = error;
            } else {
                message = "予期せぬエラーが発生しました";
            }
            setDateErrorMessage(message);
        } finally {
            setIsLoading(false);
        }
    }

    const addDate = async () => {
        if (isLoading || !updateDate) return;
        setIsLoading(true);

        const dt = new Date(updateDate);
        setUpdateDate(null);

        try {
            const res = await fetch(`${api_url}/admin-reserve-stop.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify({date: updateDate})
            });

            const data = await res.json() as DateAddResponseSuccess | DateAddResponseFailed;
            if (data.status === "failed") {
                setDateErrorMessage(data.message);
                return;
            }

            // 完了
            const year = (new Date(dt).getFullYear).toString();
            const month = (new Date(dt).getMonth() + 1).toString();
            fetchData(year, month);
            
        } catch (error) {
            let message = "";
            if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === "string") {
                message = error;
            } else {
                message = "予期せぬエラーが発生しました";
            }
            setDateErrorMessage(message);
            setUpdateDate(null);
        } finally {
            setIsLoading(false);
        }
    }

    const removeDate = async () => {
        if (isLoading || !updateDate || !dateDeleteId) return;
        setIsLoading(true);
        setDateDeleteConfirmMessage(null);

        const date = updateDate;
        const deleteId = dateDeleteId;
        setUpdateDate(null);
        setDateDeleteId(null);

        try {
            const res = await fetch(`${api_url}/admin-reserve-stop.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ id: deleteId }),
            });

            const data = await res.json() as DateDeleteResponseSuccess | DateDeleteResponseFailed;
            if (data.status === "failed") {
                setDateErrorMessage(data.message);
                return;
            }

            // 完了
            const year = (new Date(date).getFullYear).toString();
            const month = (new Date(date).getMonth() + 1).toString();
            fetchData(year, month);

        } catch (error) {
            let message = "";
            if (error instanceof Error) {
                message = error.message;
            } else if (typeof error === "string") {
                message = error;
            } else {
                message = "予期せぬエラーが発生しました";
            }
            setDateErrorMessage(message);
            setUpdateDate(null);
        } finally {
            setIsLoading(false);
        }
    }

    const openReserveData = (reserveId: number) => {
        navigate(`/admin/reserve/${reserveId}`);
    }

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const getCellClass = (detail: TimeDetailData) => {
        const classNames = ['cell', 'datetime-cell'];
        if (detail.status === "reserved") classNames.push('reserved');
        if (detail.reserve_id) classNames.push('disable');
        return classNames.join(' ');
    }

    const renderTable = () => {
        if (!scheduleData) return null;

        const dates = Object.keys(scheduleData);
        const times = Object.keys(scheduleData[dates[0]]);

        return (
            <div className="datetime-table">
                <div className="time-title">
                    <div className="title cell">時間</div>
                    {times.map((time) => (
                        <div key={time} className="title cell">{time}</div>
                    ))}
                </div>
                <div className="time-list">
                    {dates.map((date) => (
                        <div key={`${date}-column`} className="column">
                            <div
                                key={`${date}-title`}
                                className="title cell date-cell"
                                onClick={() => {
                                    toggleDate(date);
                                }}
                            >
                                <span>{new Date(date).getDate()}</span>
                            </div>
                            {times.map((time) => (
                                <div
                                    key={`${date}-${time}`}
                                    className={getCellClass(scheduleData[date][time])}
                                    onClick={() => {
                                        if (!scheduleData[date][time].reserve_id) {
                                            if (scheduleData[date][time].status === "reserved") {
                                                deleteStopTime(date, time);
                                            } else {
                                                toStopTime(date, time);
                                            }
                                        } else {
                                            // リンクに飛ばす
                                            openReserveData(scheduleData[date][time].reserve_id!);
                                        }
                                    }}
                                >
                                    <span>{scheduleData[date][time]['status'] === "reserved" ? "×" : "○"}</span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    const getRequestYearMonth = (baseDate: string, addMonth: number): { year: string, month: string } => {
        const thisYearMonth = new Date(baseDate);
        const requestYearMonth = new Date(thisYearMonth.setMonth(thisYearMonth.getMonth() + addMonth));
        const year = requestYearMonth.getFullYear().toString();
        const month = (requestYearMonth.getMonth() + 1).toString();
        return {
            year: year,
            month: month,
        }
    }

    return (
        <AdminUserLayout>
            <>
                <h1>予約受付停止設定画面</h1>
                <div className="stop-information">
                    <div className="stop-information-item">
                        <div className="color yellow"></div>
                        <div className="text">お客様から予約</div>
                    </div>
                    <div className="stop-information-item">
                        <div className="color gray"></div>
                        <div className="text">管理者が受付停止</div>
                    </div>
                </div>
                {!isLoading && (
                    <div className="card">
                        {scheduleData && (
                            <div className="datetime-controls">
                                <div
                                    onClick={() => {
                                        const {year, month} = getRequestYearMonth(Object.keys(scheduleData)[0], -1);
                                        fetchData(year, month);
                                    }}
                                >&laquo; 前月</div>
                                <h2>{new Date(Object.keys(scheduleData)[0]).toLocaleDateString('ja-JP', { year: 'numeric', month: 'short'})}</h2>
                                <div
                                    onClick={() => {
                                        const {year, month} = getRequestYearMonth(Object.keys(scheduleData)[0], 1);
                                        fetchData(year, month);
                                    }}
                                >翌月 &raquo;</div>
                            </div>
                        )}
                        {renderTable()}
                    </div>
                )}
                {confirmMessage && updateDateTime && (
                    <BackDropPopup>
                        <div className="datetime-confirm">
                            <p>{confirmMessage}</p>
                            <div className="actions">
                                <button
                                    className="danger"
                                    onClick={() => {
                                        addData(updateDateTime.date, updateDateTime.time);
                                    }}
                                >受付停止</button>
                                <button
                                    className="cancel"
                                    onClick={() => {
                                        setConfirmMessage(null);
                                        setUpdateDateTime(null);
                                    }}
                                >キャンセル</button>
                            </div>
                        </div>
                    </BackDropPopup>
                )}
                {deleteConfirmMessage && updateDateTime && (
                    <BackDropPopup>
                        <div className="datetime-confirm">
                            <p>{deleteConfirmMessage}</p>
                            <div className="actions">
                                <button
                                    className="primary"
                                    style={{ maxWidth: 'unset' }}
                                    onClick={() => {
                                        deleteData(updateDateTime.date, updateDateTime.time);
                                    }}
                                >受付再開</button>
                                <button
                                    className="cancel"
                                    onClick={() => {
                                        setDeleteConfirmMessage(null);
                                        setUpdateDateTime(null);
                                    }}
                                >キャンセル</button>
                            </div>
                        </div>
                    </BackDropPopup>
                )}

                {dateAddConfirmMessage && updateDate && (
                    <BackDropPopup>
                        <div className="datetime-confirm">
                            <p>{dateAddConfirmMessage}</p>
                            <div className="actions">
                                <button
                                    className="danger"
                                    onClick={() => {
                                        addDate();
                                    }}
                                >受付停止</button>
                                <button
                                    className="cancel"
                                    onClick={() => {
                                        setDateAddConfirmMessage(null);
                                        setUpdateDate(null);
                                    }}
                                >キャンセル</button>
                            </div>
                        </div>
                    </BackDropPopup>
                )}

                {dateDeleteConfirmMessage && updateDate && (
                    <BackDropPopup>
                        <div className="datetime-confirm">
                            <p>{dateDeleteConfirmMessage}</p>
                            <div className="actions">
                                <button
                                    className="primary"
                                    style={{ maxWidth: 'unset' }}
                                    onClick={() => {
                                        removeDate();
                                    }}
                                >受付再開</button>
                                <button
                                    className="cancel"
                                    onClick={() => {
                                        setDateDeleteConfirmMessage(null);
                                        setUpdateDate(null);
                                    }}
                                >キャンセル</button>
                            </div>
                        </div>
                    </BackDropPopup>
                )}

                {errorMessage && (
                    <BackDropPopup>
                        <div>
                            <p>追加に失敗しました。</p>
                            <p>理由: {errorMessage}</p>
                            <button
                                className="cancel"
                                onClick={() => {
                                    setErrorMessage(null);
                                }}
                            >閉じる</button>
                        </div>
                    </BackDropPopup>
                )}
                {deleteErrorMessage && (
                    <BackDropPopup>
                        <div>
                            <p>削除に失敗しました。</p>
                            <p>理由: {deleteErrorMessage}</p>
                            <button
                                className="cancel"
                                onClick={() => {
                                    setDeleteErrorMessage(null);
                                }}
                            >閉じる</button>
                        </div>
                    </BackDropPopup>
                )}
                {dateErrorMessage && (
                    <BackDropPopup>
                        <div>
                            <p>{dateErrorMessage}</p>
                            <button
                                className="cancel"
                                onClick={() => {
                                    setDateErrorMessage(null);
                                }}
                            >閉じる</button>
                        </div>
                    </BackDropPopup>
                )}
                {isLoading && (
                    <AdminModal>
                        <div className="loading">
                            <p>読み込み中</p>
                            <AiOutlineLoading3Quarters />
                        </div>
                    </AdminModal>
                )}
            </>
        </AdminUserLayout>
    )
}

export default AdminReserveStopPerDateTime;
