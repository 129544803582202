import React, { ChangeEvent, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import AdminUserLayout from "layouts/AdminUserLayout";
import { InputFieldDate } from "components/CalendarDate";
import { api_url } from "Config";

interface SearchCond {
    date_start: string;
    date_end: string;
    code: string;
    name: string;
    phone: string;
    email: string;
    status: "1" | "0";
}

interface ReserveDetail {
    id: number;
    date: string;
    time: string;
    name?: string;
    status: string;
}

interface ReserveListResponse {
    status: "success" | "failed",
    code: number;
    message?: string;
    data: ReserveDetail[];
}

interface GroupedReservations {
    [key: string]: ReserveDetail[];
}

const AdminReserved: React.FC = () => {
    const calculateDaysLater = (addDays: number) => {
        const date = new Date();
        date.setDate(date.getDate() + addDays);
        return date;
    }

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [searchCond, setSearchCond] = useState<SearchCond>({
        date_start: InputFieldDate(new Date()),
        date_end: InputFieldDate(calculateDaysLater(6)),
        code: "",
        name: "",
        phone: "",
        email: "",
        status: "1",
    } as SearchCond);
    const [reserveData, setReserveData] = useState<GroupedReservations | null>();

    useEffect(() => {
        const fetchReserveData = async () => {
            setIsLoading(true);
            setReserveData(null);
            setErrorMessage("");
            try {
                const res = await fetch(`${api_url}/admin-reserve-list.php`, {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(searchCond),
                });
    
                console.info(res);
    
                const data = await res.json() as ReserveListResponse;
                if (data.status !== "success") {
                    throw new Error(data.message);
                }
    
                setReserveData(groupByReserveData(data.data));
            } catch (error) {
                console.error('error', error);
                if (typeof(error) === "string") {
                    setErrorMessage(error);
                } else if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage("エラーが発生しました。時間を置いてからお試しください");
                }
            } finally {
                setIsLoading(false);
            }
        }
        fetchReserveData();
    }, [searchCond]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setSearchCond((prev) => ({
            ...prev,
            [name]: value,
        } as SearchCond));
    }

    const groupByReserveData = (data: ReserveDetail[]) => {
        return data.reduce((result: GroupedReservations, current) => {
            const date = current.date;
            if (!result[date]) {
                result[date] = [];
            }
            result[date].push(current);
            return result;
        }, {});
    }

    return (
        <AdminUserLayout>
            <h1>予約一覧</h1>
            <div className="admin-reserve-cond">
                <div>
                    <div>
                        <input
                            type="date"
                            name="date_start"
                            value={searchCond.date_start}
                            onChange={handleInputChange}
                            placeholder="絞り込みFROM"
                        />
                        <span>〜</span>
                        <input
                            type="date"
                            name="date_end"
                            value={searchCond.date_end}
                            onChange={handleInputChange}
                            placeholder="絞り込みTO"
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            name="code"
                            value={searchCond.code}
                            onChange={handleInputChange}
                            placeholder="予約コード"
                        />
                        <input
                            type="text"
                            name="name"
                            value={searchCond.name}
                            onChange={handleInputChange}
                            placeholder="名前"
                        />
                        <input
                            type="tel"
                            name="phone"
                            value={searchCond.phone}
                            onChange={handleInputChange}
                            placeholder="電話番号"
                        />
                        <input
                            type="email"
                            name="email"
                            value={searchCond.email}
                            onChange={handleInputChange}
                            placeholder="メールアドレス"
                        />
                    </div>
                </div>
            </div>
            <div className="admin-reserve-items">

                {isLoading && (
                    <div className="loading">
                        <AiOutlineLoading3Quarters size={30} />
                    </div>
                )}
                {reserveData &&
                    Object.keys(reserveData).map((date) => (
                        <div className="reserve-date-item" key={date}>
                            <h3>{date}</h3>
                            <ul className="reserve-list">
                                {reserveData[date].map((reserve) => (
                                    <li key={reserve.id}>
                                        <a href={`/admin/reserve/${reserve.id}`}>
                                            <span className="time">{reserve.time}</span>
                                            <span className="name">{reserve.name} 様</span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))
                }
                {!isLoading && !errorMessage && reserveData && Object.keys(reserveData).length === 0 && (
                    <div>データ 0件</div>
                )}
                {!isLoading && errorMessage && (
                    <div className="error">
                        {errorMessage}
                    </div>
                )}
            </div>
        </AdminUserLayout>
    )
}

export default AdminReserved
