import logo from "assets/images/logo.svg";
import "assets/scss/components/cource.scss";

const Course = () => {
    return (
        <>
            <div className="section course">
                <div className="section--container">
                    <p className="course--logo fadeload">
                        <img src={logo} alt="SERENA" />
                    </p>
                    <p className="course--title fadeload">COURSE MENU</p>
                    <p className="course--subtitle fadeload">以下メニューが全て含まれています。</p>
                    
                    <div className="menus">
                        <div className="menu menu--counseling fadeload">
                            <div className="menu--text">
                                <h3>カウンセリング</h3>
                                <p>一人ひとりに合わせたトレーニングプログラムを提供するため、初回にはカウンセリングを行っています。<br />
                                カウンセリングでは、あなたの身体の状態や目標、トレーニングに対する不安や疑問点などをお聞きし、最適なトレーニングプログラムを作成するための情報を収集していきます。</p>
                            </div>
                        </div>
                        <div className="menu menu--personal fadeload">
                            <div className="menu--text">
                                <h3>パーソナルトレーニング</h3>
                                <p>SERENAでは、全国2位に輝いたインストラクター 橋本拓也が、クライアント一人ひとりに合わせたパーソナルトレーニングを提供しています。皆様の身体の状態や目標、トレーニング経験などを考慮し、カスタマイズされたトレーニングメニューを提供しています。効率的に身体を変えるお手伝いを行います。</p>
                            </div>
                        </div>
                        <div className="menu menu--kick fadeload">
                            <div className="menu--text">
                                <h3>キックボクシング</h3>
                                <p>キックボクシングは、卓越した身体的なトレーニングだけでなく、自己防衛スキルを向上させ、自信を高めるものです。ストレスを解消し、内なる戦士の精神を活かす絶好の手段です。経験豊富なインストラクターが、正しいテクニックを指導し、初心者から上級者まで安全なトレーニング環境を提供します。</p>
                            </div>
                        </div>
                        <div className="menu menu--feedback fadeload">
                            <div className="menu--text">
                                <h3>
                                    <span className="line">体組成計での計測と</span>
                                    <span className="line">細かなフィードバック</span>
                                </h3>
                                <p>体組成計を使うことで、体脂肪率や筋肉量、水分量など、体の内部状態を正確に測定することができます。<br />
                                これにより、皆様の身体の状態を把握し、トレーニングや食事管理のアドバイスに反映させることができます。</p>
                            </div>
                        </div>
                        <div className="menu menu--stretch fadeload">
                            <div className="menu--text">
                                <h3>ストレッチ</h3>
                                <p>トレーニング後に行うストレッチも大切な要素の一つとして位置づけています。<br />
                                仕上げの部分まで最後まで身体を柔軟にし、筋肉の緊張を解き筋肉を緩め、疲労回復を促進し、トレーニングの効果を高めることができます。</p>
                            </div>
                        </div>
                        <div className="menu menu--advice fadeload">
                            <div className="menu--text">
                                <h3>毎日の⾷事管理とアドバイス</h3>
                                <p>食事管理のアドバイスを行います。<br />
                                健康的な食生活は、トレーニング効果を最大限に引き出すために欠かせません。<br />
                                皆様のライフスタイルや目標に合わせた食事アドバイスを提供し、毎日の食事管理に役立てることができます。</p>
                            </div>
                        </div>
                        <div className="menu menu--included fadeload">
                            <div className="menu--text">
                                <h3>
                                    <span className="line">ドリンク、ウェア、シューズ<span className="hide">、</span></span>
                                    <span className="line">タオル、アメニティ類</span>
                                </h3>
                                <p>SERENAでは、ドリンク、ウェア、シューズ、タオル、アメニティ類がすべて込みのサービスを提供しています。トレーニングに必要なウェアやシューズがなくても、ジムに来るだけでOKです。手ぶらで気軽にトレーニングを楽しめるので、初めての方や忙しい方にもおすすめです。</p>
                            </div>
                        </div>
                        <div className="others fadeload">
                            <p>その他お気軽にご相談ください！</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Course;