import { useEffect } from 'react';
import { line_link } from 'Config';
import 'assets/scss/components/contact.scss';
import imgContactByForm from 'assets/images/contact/by-form.png';
import imgContactByLine from 'assets/images/contact/by-line.png';
import imgContactByPhone from 'assets/images/contact/tap.png';

const Contact = () => {
    const checkIsMobile = () => {
        const ua = window.navigator.userAgent;
        if (ua.indexOf("Android") > -1) return true;
        if (ua.indexOf("iPhone") > -1) return true;
        return false;
    }
    useEffect(() => {
        const callForPc = document.querySelector('.phone-for-disable-call') as HTMLDivElement | null;
        const callForMb = document.querySelector('.phone-for-enable-call') as HTMLDivElement | null;
        if (checkIsMobile()) {
            // mobile
            callForPc!.style.display = "none";
            callForMb!.style.display = "block";
        } else {
            // pc
            callForPc!.style.display = "block";
            callForMb!.style.display = "none";
        }
    }, []);
    const tapFormButton = () => {
        const formWrap = document.querySelector('#form-contact') as HTMLDivElement | null;
        const position = formWrap?.offsetTop;
        window.scrollTo({
            top: position,
            behavior: 'smooth',
        });
        return false;
    }

    const tapLineButton = () => {
        window.open(line_link);
        return false;
    }

    const tapPhoneButton = () => {
        window.location.href = 'tel:05035952865';
    }

    return (
        <>
            <section className="section contact fadeload">
                <div className="section--container">
                    <div className='contact--primary'>
                        <button>
                            <img src={imgContactByForm} onClick={tapFormButton} alt="フォームで問い合わせ" className='form-open' loading='eager' />
                        </button>
                        <button>
                            <img src={imgContactByLine} onClick={tapLineButton} alt="LINEで問い合わせ" className='line-add' loading='eager' />
                        </button>
                    </div>
                    <div className='contact--phone'>
                        <p className='title'>お電話での問い合わせはこちら</p>
                        <div className='phone-for-enable-call'>
                            <button className='phone-call'>
                                <img src={imgContactByPhone} onClick={tapPhoneButton} alt="050-3595-2865 *TAPでお電話可能です" loading='eager' />
                            </button>
                        </div>
                        <div className='phone-for-disable-call'>
                            <span className='phone-call'>050-3595-2865</span>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Contact;