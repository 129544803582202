import React, { ChangeEvent, FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api_url } from "Config";
import AdminUserLayout from "layouts/AdminUserLayout";

type UserDataResponse = {
    status: "success" | "failed";
    code: number;
    message?: string | undefined;
    id: number;
}

type UserData = {
    login_id: string;
    password: string;
    confirmation: string;
}

const AdminUserAdd: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [resultMessage, setResultMessage] = useState<string>("");
    const [resultStatus, setResultStatus] = useState<"" | "success" | "error">("");
    const [userData, setUserData] = useState<UserData>({
        login_id: "",
        password: "",
        confirmation: ""
    });
    const navigate = useNavigate();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await fetch(`${api_url}/admin-user.php`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userData),
            });

            const data = await res.json() as UserDataResponse;

            if (data.code !== 200) {
                throw new Error(data.message);
            }

            navigate(`/admin/user/${data.id}`);

        } catch (error) {
            setResultStatus("error");
            if (typeof error === "string") {
                setResultMessage(error);
            } else if (error instanceof Error) {
                setResultMessage(error.message);
            } else {
                setResultMessage("failed");
            }
        } finally {
            setIsLoading(false);
        }
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevUserData) => ({
            ...prevUserData,
            [name]: value,
        }) as UserData);
    };

    return (
        <AdminUserLayout>
            {isLoading && (
                <div>Loading...</div>
            )}
            {!isLoading && (
                <div>
                    {resultMessage && (
                        <p className={resultStatus !== "" ? `ft-${resultStatus}` : ""}>{resultMessage}</p>
                    )}
                    <form name="form" onSubmit={handleSubmit}>
                        <div className="data-view">
                            <dl className="data-item">
                                <dt>ログインID</dt>
                                <dd>
                                    <input
                                        type="email"
                                        name="login_id"
                                        value={userData.login_id}
                                        onChange={handleInputChange}
                                    />
                                </dd>
                            </dl>

                            <dl className="data-item">
                                <dt>パスワード</dt>
                                <dd>
                                    <input
                                        type="password"
                                        name="password"
                                        value={userData.password}
                                        onChange={handleInputChange}
                                    />
                                </dd>
                            </dl>

                            <dl className="data-item">
                                <dt>パスワード確認</dt>
                                <dd>
                                    <input
                                        type="password"
                                        name="confirmation"
                                        value={userData.confirmation}
                                        onChange={handleInputChange}
                                    />
                                </dd>
                            </dl>
                        </div>
                        <div className="align-center">
                            <button className="primary">保存</button>
                        </div>
                    </form>
                </div>
            )}
        </AdminUserLayout>
    )
}

export default AdminUserAdd
