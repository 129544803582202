import 'assets/scss/components/try.scss';
import 'assets/scss/utils/_text.scss';
import visual from 'assets/images/try/visual.png';

const Try = () => {
    return (
        <>
            <section className='section try'>
                <div className='section--content'>
                    <p className='visual fadeload'><img src={visual} alt="ボクササイズイメージ" loading='eager' /></p>
                    <h3 className='fadeload'>まずはお気軽に<span className='ft-yellow'>ご体験</span>ください!</h3>
                </div>
            </section>
        </>
    )
};

export default Try;
