import Map from 'components/Map';
import 'assets/scss/components/access.scss';
import logo from 'assets/images/logo.svg';

const Access = () => {
    return (
        <>
            <section className="section access">
                <div className="section--content">
                    <p className='access--logo fadeload'><img src={logo} alt="SERENA" loading='eager' /></p>
                    <div className='access--title fadeload'>
                        <h2>パーソナルトレーニングジム</h2>
                        <h3>SERENA (セレナ) </h3>
                        <h4>新宿御苑・四谷三丁目店</h4>
                    </div>
                    <div className='access--info fadeload'>
                        <p>営業時間 ■ 10:00〜22:00<br />
                        電話番号 ■ 050-3595-2865<br />
                        住所 ■ 〒160-0004 東京都新宿区四谷4丁目28-6 6F</p>
                    </div>
                </div>
            </section>
            <Map />
        </>
    )
};

export default Access;