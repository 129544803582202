import React from "react";

const sanitizeAndSplitText = (text: string) => {
    // DOMPurifyでサニタイズ
    // const sanitizedText = DOMPurify.sanitize(text);
    // 改行で分割
    return text.split('\n').map(escapeHtmlEntities);
};

const escapeHtmlEntities = (text: string) => {
    return text;
};

const SanitizeInput = (input: string | null) => {
    if (input === null) return null;
    const lines = sanitizeAndSplitText(input);
    
    return (
        lines.map((line, index) => (
            <React.Fragment key={index}>{line}{index !== lines.length - 1 && (<br />)}</React.Fragment>
        ))
    );
}

export default SanitizeInput;