import { useEffect } from 'react';
import Logo from "components/Logo";
import Header from "components/Header";
import Natulary from "components/Naturaly";
import FirstOffer from "components/FirstOffer";
import Contact from "components/Contact";
import Try from "components/Try";
import Speciality from "components/Speciality";
import About from "components/About";
import Course from "components/Course";
import System from "components/System";
import Access from "components/Access";
import Form from "components/Form";
import Footer from "components/Footer";
import { fadeLoad } from "utils/fade_load";
import 'assets/scss/home.scss';
import 'assets/scss/utils/fade_load.scss';
import { Helmet } from 'react-helmet-async';

const HomePage = () => {
    useEffect(() => {
        fadeLoad();
    }, []);
    return (
        <>
            <Helmet>
                <title>自然とモテる身体を実現する SERENA</title>
                <meta name='description' content='最先端のトレーニング技術を駆使した新宿御苑・四谷三丁目のパーソナルトレーニングジム SERENA(セレナ)' />
            </Helmet>
            <Logo />
            <Header />
            <Natulary />
            <FirstOffer />
            <Try />
            <Contact />
            <Speciality />
            <About />
            <Course />
            <System />
            <Contact />
            <Access />
            <Form />
            <Footer />
        </>
    )
}

export default HomePage;
