import React from "react";
import { createRoot } from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

import App from "App";

const root = createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Helmet>
                <title>MyApp</title>
                <meta name='keyword' content='' />
                <meta name='description' content='' />
                <meta name='robots' content='index, follow' />
            </Helmet>
            <App />
        </HelmetProvider>
    </React.StrictMode>
);
