import 'assets/scss/components/first-offer.scss';
import price from 'assets/images/first-offer/price.png';

const FirstOffer = () => {
    return (
        <>
            <section className='section firstOffer'>
                <div className='firstOffer--content'>
                    <p className='price fadeload'><img src={price} alt="初回体験50分 5,000円 国内最高級クラスプロテイン付き" loading='eager' /></p>
                    <div className='text fadeload'>
                        <h2>初回体験で、是非ご体感ください。</h2>
                        <p>広々とした施設と最新のトレーニング機器を完備しており、快適な空間で無理なくトレーニングを楽しんでいただけます。初めての方も安心してご参加いただけるよう、フレンドリーで熱心なスタッフが心温まるサポートを提供いたします。</p>
                    </div>
                </div>
            </section>
        </>
    )
};

export default FirstOffer;
