import AdminUserLayout from "layouts/AdminUserLayout";
import React from "react";

const AdminDashboard: React.FC = () => {
    return <AdminUserLayout>
        <p>左メニューから操作してください</p>
    </AdminUserLayout>
}

export default AdminDashboard
