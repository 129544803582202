import Instagram from '../utils/Instagram';
import Tiktok from '../utils/Tiktok';
import 'assets/scss/components/about.scss';
import mv from 'assets/images/about/training-image.png';
import logoPc from 'assets/images/about/logo-pc.png';
import logoMb from 'assets/images/about/logo-mb.png';
import imagePc from 'assets/images/about/training-image2-pc.png';
import imageMb from 'assets/images/about/training-image2-mb.png';
import copyPc from 'assets/images/about/catchcopy-pc.png';
import copyMb from 'assets/images/about/catchcopy-mb.png';

const About = () => {
    return (
        <>
            <section className='section about'>
                <div className='section--container'>
                    <div className='mv fadeload'>
                        <img src={mv} alt="トレーニングイメージ" loading='eager' />
                    </div>
                    <div className='message fadeload'>
                        <div className='message--logo'>
                            <img src={logoPc} className='message--logo__pc' alt="SERENA" loading='eager' />
                            <img src={logoMb} className='message--logo__mb' alt="SERENA" loading='eager' />
                        </div>
                        <div className='message--text'>
                            <p>年間約180名、延べ4000本を超えるセッション通し<br />
                            お客様のボディメイクを成功へ導いてきた<br />
                            実績があるトレーナー陣が在籍！<br />
                            女性トレーナーも活躍しております！</p>
                        </div>
                    </div>
                    <div className='sns fadeload'>
                        <div className='sns--instagram'>
                            <Instagram />
                        </div>
                        <div className='sns--tiktok'>
                            <Tiktok />
                        </div>
                    </div>

                    <div className='training'>
                        <p className='training--image fadeload'>
                            <img src={imagePc} className='training--image__pc' alt="トレーニングイメージ2" loading='eager' />
                            <img src={imageMb} className='training--image__mb' alt="トレーニングイメージ2" loading='eager' />
                        </p>
                        <div className='training--text fadeload'>
                            <p>SERENAでは従来のパーソナルジムにはない環境でトレーニングを提供致します。<br />
                            マシントレーニングの他、キックボクシング・HIITトレーニング・レスミルズプログラム・バトルロープなど、楽しく身体を動かす動きを取り入れることでマンネリ化を防ぎます。<br />
                            自由にカスタマイズ可能なので、その日の気分や体調に合わせて理想のスタイルへ導きます。</p>
                        </div>
                    </div>

                    <div className='catchcopy fadeload'>
                        <img src={copyPc} className='catchcopy__pc' alt="Achieving Your Ideal Body" loading='eager' />
                        <img src={copyMb} className='catchcopy__mb' alt="Achieving Your Ideal Body" loading='eager' />
                    </div>
                </div>
            </section>
        </>
    )
};

export default About;