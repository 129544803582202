import React from "react";

const ClientLoading: React.FC = () => {
    return (
        <div className="backdrop-loading">
            <div className="loading-container">
                Loading...
            </div>
        </div>
    )
}

export default ClientLoading
