import React, { useState } from 'react';
import 'assets/scss/components/form.scss';

type ResponseJson = {
    status: string,
    message: string | null,
};

const Form = () => {

    // close backdrop
    const onCloseBackdrop = () => {
        const formSection = document.querySelector('#form-contact') as HTMLDivElement | null;
        formSection!.classList.remove('sending');
    }

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [detail, setDetail] = useState<string>('');
    const [responseTitle, setResponseTitle] = useState<string>('');
    const [responseMessage, setResponseMessage] = useState<string>('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        switch (name) {
            case 'name':
                setName(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'phone':
                setPhone(value);
                break;
            case 'detail':
                setDetail(value);
                break;
            default:
                break;
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formSection = document.querySelector('#form-contact') as HTMLDivElement | null;
        formSection!.classList.add('sending');

        try {
            const endpoint = "https://personalgym-serena.com/api/contact/index.php";
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    phone,
                    detail,
                }),
            });

            const data = await response.json() as ResponseJson;
            if (data.status !== 'Success') {
                setResponseTitle("送信できません。");
                setResponseMessage(data.message!);
            } else {
                setResponseTitle("送信を受け付けました。");
                setResponseMessage("返信までしばらくお待ちください。");
                setName('');
                setEmail('');
                setPhone('');
                setDetail('');
            }
            
        } catch (error) {
            console.error(error);
            setResponseTitle("エラーが発生しました");
            setResponseMessage("恐れ入りますが、お電話かLINEにてお問い合わせください。");
        }
    }

    return (
        <>
            <section className="section form" id="form-contact">
                <div className="section--content fadeload">
                    <p className="form--title">お問い合わせフォーム</p>
                    <form method="post" id="form-contact-primary" onSubmit={handleSubmit}>
                        <div className="form--element">
                            <dl>
                                <dt className="require">お名前</dt>
                                <dd>
                                    <input type="text" name="name" value={name} onBlur={handleInputChange} onChange={handleInputChange} />
                                </dd>
                            </dl>

                            <dl>
                                <dt className="require">メールアドレス</dt>
                                <dd>
                                    <input type="email" name="email" value={email} onBlur={handleInputChange} onChange={handleInputChange} />
                                </dd>
                            </dl>

                            <dl>
                                <dt className="freeform">電話番号</dt>
                                <dd>
                                    <input type="tel" name="phone" value={phone} onBlur={handleInputChange} onChange={handleInputChange} />
                                </dd>
                            </dl>

                            <dl>
                                <dt className="require">内容</dt>
                                <dd>
                                    <textarea name="detail" value={detail} onBlur={handleInputChange} onChange={handleInputChange}></textarea>
                                </dd>
                            </dl>
                        </div>
                        <div className="buttons">
                            <input type="submit" className="btn btn-send" value="送信" />
                        </div>
                    </form>
                </div>
                <div className='backdrop'>
                    <div className='backdrop--container'>
                        <h4 className='backdrop--title'>{responseTitle}</h4>
                        <p className='backdrop--message'>{responseMessage}</p>
                        <p className='backdrop--bottom'>
                            <button className='close' onClick={onCloseBackdrop}>閉じる</button>
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Form;
