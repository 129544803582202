import { useState, useEffect} from 'react';
import { instagram_account_id, instagram_account_name, instagram_account_title } from 'Config';
import InstagramSvg from 'utils/instagram_svg';

const Instagram = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        if (!isLoaded) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;

            const attr = document.createAttribute('src');
            attr.value = '//www.instagram.com/embed.js';
            script.setAttributeNode(attr);
            
            const head = document.querySelector('head') as HTMLHeadElement | null;
            head!.appendChild(script);

            setIsLoaded(true);
        }
    }, [isLoaded]);

    const link_href = `https://www.instagram.com/p/${instagram_account_id}/?utm_source=ig_embed&amp;utm_campaign=loading`;
    
    return (
        <>
            <blockquote
                className='instagram-media'
                data-instagram-captioned
                data-instagram-permalink={link_href}
                data-instagram-version='14'
                style={{
                    background: '#fff',
                    border: '0',
                    borderRadius: '3px',
                    boxShadow: '0 0 1px 0 rgba(0, 0, 0, .5), 0 1px 10px 0 rgba(0, 0, 0, .15)',
                    margin: '1px',
                    maxWidth: '100%',
                    minWidth: '100px',
                    padding: '0',
                    width: 'calc(100% - 2px)',
                }}
            >
                <div style={{ padding: '16px' }}>
                    <a
                        href={link_href}
                        target='_blank'
                        style={{
                            background: '#fff',
                            lineHeight: '0',
                            padding: '0 0',
                            textAlign: 'center',
                            textDecoration: 'none',
                            width: '100%',
                        }}
                        rel="noreferrer"
                    >
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}>
                            <div style={{
                                backgroundColor: '#f4f4f4',
                                borderRadius: '50%',
                                flexGrow: 0,
                                height: '40px',
                                marginRight: '14px',
                                width: '40px',
                            }}></div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: '1',
                                justifyContent: 'center',
                            }}>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: '4px',
                                    flexGrow: '0',
                                    height: '14px',
                                    marginBottom: '6px',
                                    width: '100%',
                                }}></div>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: '4px',
                                    flexGrow: '0',
                                    height: '14px',
                                    widows: '60px',
                                }}></div>
                            </div>
                        </div>
                        <div style={{ padding: '19% 0' }}></div>
                        <div style={{
                            display: 'block',
                            height: '50px',
                            margin: '0 auto 12px',
                            width: '50px',
                        }}>
                            <InstagramSvg />
                        </div>
                        <div style={{ paddingTop: '8px' }}>
                            <div style={{
                                color: '#3897f0',
                                fontFamily: 'Arial,sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '550',
                                lineHeight: '18px'
                            }}>
                                この投稿をInstagramで見る
                            </div>
                        </div>
                        <div style={{ padding: '12.5% 0' }}></div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginBottom: '14px',
                            alignItems: 'center',
                        }}>
                            <div>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: '50%',
                                    height: '12.5px',
                                    width: '12.5px',
                                    transform: 'translateX(0px) translateY(7px)',
                                }}></div>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    height: '12.5px',
                                    transform: 'roatte(-45deg) translateX(3px) translateY(1px)',
                                    width: '12.5px',
                                    flexGrow: '0',
                                    marginRight: '14px',
                                    marginLeft: '2px',
                                }}></div>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: '50%',
                                    height: '12.5px',
                                    width: '12.5px',
                                    transform: 'translateX(9px) translateY(-18px)',
                                }}></div>
                            </div>
                            <div style={{ marginLeft: '8px' }}>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: '50%',
                                    flexGrow: '0',
                                    height: '20px',
                                    width: '20px',
                                }}></div>
                                <div style={{
                                    width: '0',
                                    height: '0',
                                    borderTop: '2px solid transparent',
                                    borderLeft: '6px solid #f4f4f4',
                                    borderBottom: '2px solid transparent',
                                    transform: 'translateX(16px) translateY(-4px) rotate(30deg)',
                                }}></div>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <div style={{
                                    width: '0',
                                    borderTop: '8px solid #f4f4f4',
                                    borderRight: '8px solid transparent',
                                    transform: 'translateY(16px)',
                                }}></div>
                                <div style={{
                                    backgroundColor: '#f4f4f4',
                                    flexGrow: '0',
                                    height: '12px',
                                    width: '16px',
                                    transform: 'translateY(-4px)',
                                }}></div>
                                <div style={{
                                    width: '0',
                                    height: '0',
                                    borderTop: '8px solid #f4f4f4',
                                    borderLeft: '8px solid transparent',
                                    transform: 'translateY(-4px) translateX(8px)',
                                }}></div>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1',
                            justifyContent: 'center',
                            marginBottom: '24px',
                        }}>
                            <div style={{
                                backgroundColor: '#f4f4f4',
                                borderRadius: '4px',
                                flexGrow: '0',
                                height: '14px',
                                marginBottom: '6px',
                                width: '100%',
                            }}></div>
                            <div style={{
                                backgroundColor: '#f4f4f4',
                                borderRadius: '4px',
                                flexGrow: '0',
                                height: '14px',
                                width: '144px',
                            }}></div>
                        </div>
                    </a>
                    <p style={{
                        color: '#c9c8cd',
                        fontFamily: 'Arial, sans-serif',
                        fontSize: '14px',
                        lineHeight: '17px',
                        marginBottom: '0',
                        marginTop: '8px',
                        overflow: 'hidden',
                        padding: '8px 0 7px',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>
                        <a
                            href={link_href}
                            target='_blank'
                            style={{
                                color: '#c9c8cd',
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                lineHeight: '17px',
                                textDecoration: 'none',
                            }}
                            rel='noreferrer'
                        >
                            {instagram_account_title}(@{instagram_account_name})がシェアした投稿
                        </a>
                    </p>
                </div>
            </blockquote>
        </>
    )
};

export default Instagram;
